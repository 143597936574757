import { useEffect, useRef, useState } from "react"
import picturesApi from "../services/picturesApi"
import authApi from "../services/authAPI"
import config from "../config/api.config"
import { useNavigate } from "react-router-dom"
import { fri9g_notify } from "fri9g-notification-react"
import Loading from "../components/Loading"
import logo from './../ressources/logo.png'


const Login = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const [username,setUsername] = useState("")
  const [password,setPassword] = useState("")

  const [loading,setLoading] = useState(false)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }


  useEffect(()=>{
    setRoute("Login")
  },[setRoute])

  useEffect(()=>{
    if(authApi.isAuthenticated()) navigate("/")
  },[])

  const Login = async (e) => {
    setLoadingRef(true)
    e.preventDefault()
    let log = await authApi.login(username,password)
    if(log) {
      fri9g_notify.success("Connexion réussie")
      return navigate("/")
    }
    else {
      fri9g_notify.error("Connexion échouée")
      setLoadingRef(false)
    }
  }

  return (<div className="login">
    <form className="form">
      <h1>Bienvenue</h1>
      <div className="logo"><img src={logo} alt="lFriShopping.com logo" /></div>
      <div className="form-input">
        <input type="text" name="username" id="username" placeholder="Identifiant" value={username} onChange={(e)=>{setUsername(e.target.value)}}/>
      </div>
      <div className="form-input">
        <input type="password" name="password" id="password" placeholder="Password" value={password} onChange={(e)=>{setPassword(e.target.value)}}/>
      </div>
      <button onClick={(e) => {Login(e)}} disabled={loading}>{loading?<Loading/>:<span>Login</span>}</button>
      <p>Un soucis ? <a href="mailto:contact@fri9g.com">Contactez l'équipe de developpement</a></p>
    </form>
  </div>)
}

export default Login