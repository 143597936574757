const slug = (length=64) => {
  var characters = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var chars = characters.split("")
  var slug = ""
  while(length>0) {
    slug+=chars[Math.floor(Math.random()*chars.length)]
    length--
  }
  return slug
}

const CS = {
  slug
}
export default CS