import { useEffect, useRef, useState } from "react"
import authApi from "../services/authAPI"
import { Link, useNavigate } from "react-router-dom"
import moment from "moment"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHouse, faPowerOff, faBookmark, faDollarSign, faFlag, faLanguage, faBox, faCashRegister, faUsers, faUserGear, faUserPen } from "@fortawesome/free-solid-svg-icons"
import logo from './../ressources/logo.png'


const Menu = (props) => {
  const navigate = useNavigate()
  const formatDate = (str) => moment(str).format('YYYY')
  const setMenuActive = props.setMenuActive
  const menuActive = props.menuActive
  const route = props.route

  return (<nav className="menu">
  <div className="header" onClick={(e)=>{setMenuActive(!menuActive)}}>
    <img src={logo} alt={"logo of FriShopping"} />
  </div>
  <div className="nav">
    <Link className={route==="Dashboard" ? "nav-object active" :"nav-object"} to="/"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faHouse} /></span><h4 className="nav-title">Dashboard</h4>
    </Link>
    <Link className={route==="Category" ? "nav-object active" :"nav-object"} to="/categories"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faBookmark} /></span><h4 className="nav-title">Category</h4>
    </Link>
    <Link className={route==="Currency" ? "nav-object active" :"nav-object"} to="/currencies"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faDollarSign} /></span><h4 className="nav-title">Currency</h4>
    </Link>
    <Link className={route==="Country" ? "nav-object active" :"nav-object"} to="/countries"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faFlag} /></span><h4 className="nav-title">Country</h4>
    </Link>
    <Link className={route==="Language" ? "nav-object active" :"nav-object"} to="/languages"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faLanguage} /></span><h4 className="nav-title">Language</h4>
    </Link>
    <Link className={route==="Order" ? "nav-object active" :"nav-object"} to="/orders"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faBox} /></span><h4 className="nav-title">Order</h4>
    </Link>
    <Link className={route==="Product" ? "nav-object active" :"nav-object"} to="/products"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faCashRegister} /></span><h4 className="nav-title">Product</h4>
    </Link>
    <Link className={route==="User" ? "nav-object active" :"nav-object"} to="/users"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faUsers} /></span><h4 className="nav-title">User</h4>
    </Link>
    <Link className={route==="Role" ? "nav-object active" :"nav-object"} to="/roles"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faUserGear} /></span><h4 className="nav-title">Role</h4>
    </Link>
    <Link className={route==="Support" ? "nav-object active" :"nav-object"} to="/support"> 
      <span className="nav-icon"><FontAwesomeIcon icon={faUserPen} /></span><h4 className="nav-title">Support</h4>
    </Link>
  </div>
  <div className="footer">
    <h2>{authApi.get("username")}</h2>
    <h5 onClick={(e)=>{authApi.logout();navigate("/login")}}> <FontAwesomeIcon icon={faPowerOff} /> Logout</h5>
  </div>
  <div className="footer-copy">
    <div className="footer-copy-right">
      <p>Copyright &copy; <Link to="https://www.fri9g.com">Fri9g</Link> - { formatDate() } </p>
    </div>
  </div>
</nav>)
}

export default Menu