import { useEffect, useRef, useState } from "react"
import PopUpWindow from "../components/PopUpWindow"
import authApi from "../services/authAPI"
import { useNavigate } from "react-router-dom"
import { fri9g_notify } from "fri9g-notification-react"
import config from "../config/api.config"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faSquarePlus } from "@fortawesome/free-regular-svg-icons"
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"

const Role = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute

  var __access = {"role": false, "user": false, "order": false, "country": false, "product": false, "support": false, "category": false, "currency": false, "language": false, "user_edit": false, "newsletter": false, "order_edit": false, "user_admin": false, "product_edit": false, "category_edit": false}

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("Role")
    setAccessRef(authApi.get("access"))
    loadDatas()
  },[setRoute])

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [roles,setRoles] = useState([])
  const rolesRef = useRef(roles)
  const setRolesRef = (data) => {
    rolesRef.current = data
    setRoles(data)
  }

  const [role,setRole] = useState({loaded:false})
  const roleRef = useRef(role)
  const setRoleRef = (data) => {
    roleRef.current = data
    setRole(data)
  }

  const [access,setAccess] = useState({})
  const accessRef = useRef(access)
  const setAccessRef = (data) => {
    accessRef.current = data
    setAccess(data)
  }

  const submit = async () => {
    var link = config.api.url+`roles/${roleRef.current.id ? (roleRef.current.delete ? 'delete' : 'edit') : 'new'}?locale=fr`
    var body = roleRef.current
    if(!roleRef.current.delete) {
      if(!body.name) {
        fri9g_notify.warning("Must be a name for the role")
        return
      }
      if(!body.weight || body.weight < 1 || body.weight > 10) {
        fri9g_notify.warning("Must be a weight for the role beetwen 1 and 10")
        return
      }
      if(!body.access) {
        fri9g_notify.warning("Must be an access list for the role")
        return
      }
    }
    await Axios.post(link,body).then(resp => {
      if(roleRef.current.delete) fri9g_notify.success('Role Deleted')
      else if(roleRef.current.id) fri9g_notify.success('Role Edited')
      else fri9g_notify.success('Role Added')
      loadDatas()
      setRoleRef({loaded:false})
    }).catch(err => {
      if(roleRef.current.delete) fri9g_notify.error('Error while deleting the role : '+err.response.data.message)
      else if(roleRef.current.id) fri9g_notify.error('Error while editing the role : '+err.response.data.message)
      else fri9g_notify.error('Error while adding the role : '+err.response.data.message)
    })
  }

  const loadDatas = async () => {
    setLoadingRef(true)
    await Axios.get(config.api.url+'roles').then(resp => { 
      setRolesRef(resp.data.role)
      setLoadingRef(false)
    })
  }

  return (<div className="page role">
    {loadingRef.current ? <Loading/>: <>
      <div className="page-action">
        <button className="button add-button" onClick={(e)=>{e.preventDefault();setRoleRef({
            name:'',
            weight:1,
            access:{"role": false, "user": false, "order": false, "country": false, "product": false, "support": false, "category": false, "currency": false, "language": false, "user_edit": false, "newsletter": false, "order_edit": false, "user_admin": false, "product_edit": false, "category_edit": false},
            loaded:true
          })}}>
          <FontAwesomeIcon icon={faSquarePlus} />
        </button>
      </div>

      {/* List part */}
      <div className="list-clipboard">
        <div className="list-title">
          <div>Name</div>
          <div>Weight</div>
          <div>Access</div>
          <div>Nb users</div>
          <div></div>
        </div>
        {rolesRef.current.length > 0 && rolesRef.current.map((e,k) => <div className="list-entity" key={'list-'+k}>
          <div>{e.name}</div>
          <div>{e.weight}</div>
          <div>{Object.values(e.access).reduce((a,b)=>a+b,0)} access</div>
          <div>{e.users}</div>
          <div>
            {Object.keys(e.access).length !== Object.keys(__access) && Object.keys(__access).map(el => {
              if(!e.access[el]) e.access[el] = false
            })}
            <button className="button edit-button" onClick={(ev)=>{ev.preventDefault();setRoleRef({...e,role:e.id,loaded:true})}}><FontAwesomeIcon icon={faPenToSquare} /></button>
            <button className={'button delete-button '+(e.default ? 'disabled-button' : '')} disabled={e.default} onClick={(ev)=>{ev.preventDefault();setRoleRef({id:e.id,role:e.id,delete:true,loaded:true})}}><FontAwesomeIcon icon={faTrashCan} /></button>
          </div>
        </div> )}
      </div>

      {/* window form part */}
      {roleRef.current.loaded && 
        <PopUpWindow className='role-form' close={()=>{setRoleRef({loaded:false})}}>
          {!roleRef.current.delete && <><h2>{roleRef.current.id ? "Edition du role "+roleRef.current.id : "Ajout d'un role"}</h2>
          <div className="input name">
            <h4>Name </h4> <input type="text" name="role-name" id="role-name" data-form-type="other" value={roleRef.current.name} onChange={(e)=>{e.preventDefault();let r = {...roleRef.current};r.name=e.target.value;setRoleRef(r)}}/>
          </div>
          <div className="input weight">
            <h4>Weight </h4> <input type="number" min="1" max="10" name="role-number" id="role-number" data-form-type="other" value={roleRef.current.weight} onChange={(e)=>{e.preventDefault();let r = {...roleRef.current};r.weight=e.target.value;setRoleRef(r)}}/>
          </div>
          <div className="access">
            <h4>Access</h4>
            <div className="list">
              {roleRef.current.access && Object.keys(roleRef.current.access).length>0 && Object.keys(roleRef.current.access).map((k) => { return accessRef.current[k] ? <h5 key={k+'-'+roleRef.current.access[k]}>
                <span>{k}</span>
                <input type="checkbox" defaultChecked={roleRef.current.access[k]} readOnly onClick={(e)=>{e.preventDefault();let r = {...roleRef.current};r.access[k]=!roleRef.current.access[k];setRoleRef(r)}}/>
              </h5> : null} )}
            </div>
          </div></>}
          <div className="submit">
            <button className={roleRef.current.delete ? 'delete' : 'success'} onClick={e=>{e.preventDefault();submit()}}>
              {roleRef.current.id ? ( roleRef.current.delete ? 'Suppression du role' : 'Modification du role' ) : 'Ajout du role'}
            </button>
            <button className="cancel" onClick={e=>{e.preventDefault();setRoleRef({loaded:false})}}>
              Cancel
            </button>
          </div>
        </PopUpWindow>}
      </>}
  </div>)
}
export default Role