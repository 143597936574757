import { useEffect, useRef, useState } from "react"
import authApi from "../services/authAPI"
import { Link, useNavigate } from "react-router-dom"
import config from "../config/api.config"
import Axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons"
import Loading from "../components/Loading"
import { faEye } from "@fortawesome/free-regular-svg-icons"
import { ResponsiveBar } from '@nivo/bar'


const Home = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const __access = authApi.get('access')

  const [datas,setDatas] = useState([])
  const datasRef = useRef(datas)
  const setDatasRef = (data) => {
    datasRef.current = data
    setDatas(data)
  }

  const countQuantity = (o) => {
    let qty = 0 
    for (let i = 0; i < o.cart.length; i++) {
      let e = o.cart[i]
      qty += e.quantity
    }
    return qty+` produit${qty>1&&'s'}`
  }

  useEffect(()=>{
    setRoute("Dashboard")
  },[setRoute])

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    else {
      Axios.get(config.api.url+'dashboard?currency=EUR').then(resp => {
        setDatasRef(resp.data)
      })
    }
  },[navigate])

  return (<div className="page dashboard">
    {console.log(datasRef.current)}
    <div className="section last-orders s2x s3y">
      {datasRef.current.orders ? <>
        <h2>Dernières commandes</h2>
        <div className="section-scroll">
          {datasRef.current.orders.map((e,k) => <div className="dash-order" key={`dashboard-orders-${k}-${e.id}`}>
            <div className="dash-order-country">
              <img src={config.url+'images/flag/'+e.country.iso.toLowerCase()+'.png'} alt={'flag of '+e.country.iso} />
            </div>
            <div className="dash-order-quantity">
              <h4>{countQuantity(e)}</h4>
            </div>
            <div className="dash-order-amount">
              <h4>{e.amount + ' ' + e.currency.symbol + ' | ' + e.currency.code}</h4>
            </div>
            <Link to={`/orders/${e.id}`} target="_blank" className="dash-order-link">
              <button className="button edit-button"><FontAwesomeIcon icon={faEye} /></button>
            </Link>
          </div>)}
        </div>
      </> : <Loading/>}
    </div>
    <div className="section s5x s2y">
      {datasRef.current.nb_orders ? <>
        <h2>Commandes par jour (nb)</h2>
        <div className="dash-graph">
          <GraphBar data={datasRef.current.nb_orders} color={"hsl(346, 70%, 50%)"} title={"Amount of order by day on the last week"}/>
        </div>
      </> :<Loading/>}
    </div>
    <div className="section s5x s2y">
      {datasRef.current.amount_orders ? <>
        <h2>Revenus par jour (€)</h2>
        <div className="dash-graph">
          <GraphBar data={datasRef.current.amount_orders} title={"Amount of currency by day on the last week"} color={"hsl(103, 70%, 50%)"} labelCplt={'€'}/>
        </div>
      </> :<Loading/>}
    </div>
    <div className={`section s2x error-section error-${datasRef.current?.nb_error>0 ? 'available' : 'none'}`}>
      {datasRef.current.nb_error!==undefined ? <>
        <FontAwesomeIcon icon={faTriangleExclamation} /><h2>{datasRef.current.nb_error} erreurs en attente</h2>
      </> :<Loading/>}
    </div>
  </div>)
}

export default Home

const GraphBar = ({ data,title,labelCplt,color }) => (
  <ResponsiveBar
      data={data}
      keys={[
          'amount'
      ]}
      indexBy="date"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={color}
      defs={[
          {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: '#38bcb2',
              size: 4,
              padding: 1,
              stagger: true
          },
          {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: '#eed312',
              rotation: -45,
              lineWidth: 6,
              spacing: 10
          }
      ]}
      fill={[]}
      borderColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1.6
              ]
          ]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'date',
          legendPosition: 'middle',
          legendOffset: 32,
          truncateTickAt: 0
      }}
      axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'amount',
          legendPosition: 'middle',
          legendOffset: -40,
          truncateTickAt: 0
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
          from: 'color',
          modifiers: [
              [
                  'darker',
                  1.6
              ]
          ]
      }}
      legends={[
          {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                  {
                      on: 'hover',
                      style: {
                          itemOpacity: 1
                      }
                  }
              ]
          }
      ]}
      role="application"
      ariaLabel={title}
      barAriaLabel={e=>{return e.id+": "+e.formattedValue+" on date : "+e.indexValue +' ' +labelCplt}}
  />
)