import { useEffect, useRef, useState } from "react"
import PopUpWindow from "../components/PopUpWindow"
import authApi from "../services/authAPI"
import { Link, useNavigate, useParams } from "react-router-dom"
import { fri9g_notify } from "fri9g-notification-react"
import config from "../config/api.config"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/free-regular-svg-icons"
import { faEye, faMagnifyingGlass, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"

const Order = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const __access = authApi.get('access')
  const {id} = useParams()

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("Order")
    setLoadingRef(true)
    loadDatas()
  },[setRoute,id])

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [submitLoading,setSubmitLoading] = useState(false)
  const submitLoadingRef = useRef(submitLoading)
  const setSubmitLoadingRef = (data) => {
    submitLoadingRef.current = data
    setSubmitLoading(data)
  }

  const [orders,setOrders] = useState([])
  const ordersRef = useRef(orders)
  const setOrdersRef = (data) => {
    ordersRef.current = data
    setOrders(data)
  }

  const [order,setOrder] = useState([])
  const orderRef = useRef(order)
  const setOrderRef = (data) => {
    orderRef.current = data
    setOrder(data)
  }

  const [cart,setCart] = useState([])
  const cartRef = useRef(cart)
  const setCartRef = (data) => {
    cartRef.current = data
    setCart(data)
  }

  const [search,setSearch] = useState('')
  const searchRef = useRef(search)
  const setSearchRef = (data) => {
    searchRef.current = data
    setSearch(data)
  }

  const __selectorData = [`id`,`user`,`session`]
  const [selector,setSelector] = useState(`id`)
  const selectorRef = useRef(selector)
  const setSelectorRef = (data) => {
    selectorRef.current = data
    setSelector(data)
  }

  const [popUp,setPopUp] = useState(false)
  const popUpRef = useRef(popUp)
  const setPopUpRef = (data) => {
    popUpRef.current = data
    setPopUp(data)
  }

  const loadDatas = async () => {
    setLoadingRef(true)
    if(id) {
      await Axios.get(config.api.url+`order/${id}?locale=fr`).then(async resp => {
        resp.data.loaded = true
        setOrderRef(resp.data)
        setLoadingRef(false)
        let cc = {}
        for (let i = 0; i < resp.data.cart.length; i++) {
          const element = resp.data.cart[i]
          const k = element.id + '|' + (element.option?element.option:'noOption')
          if(!cc[k]) {
            cc[k] = {loaded:false}
            await Axios.get(config.api.url+`product/${element.id}?locale=fr`).then(r => {
              r.data.loaded = true
              r.data.selected = element
              cc[k] = r.data
            }).catch(err => {
              cc[k] = {loaded:true,error:true}
            })
          }
        }
        setCartRef(cc)
      }).catch(err =>{
        fri9g_notify.error(`Error while charging the order : ${err?.response?.data?.message}`)
      })
    } else {
      await Axios.get(config.api.url+`order/all?locale=fr`).then(resp => {
        setOrdersRef(resp.data)
        setLoadingRef(false)
      }).catch(err =>{
        fri9g_notify.error(`Error while charging the order : ${err?.response?.data?.message}`)
      })
    }
  }

  const getSearch = async () => {
    setLoadingRef(true)
    await Axios.get(config.api.url+`order/search?parameter=${searchRef.current}&field=${selectorRef.current}&locale=fr`).then(resp => {
      setOrdersRef(resp.data)
      setLoadingRef(false)
    }).catch(err =>{
      fri9g_notify.error(`Error while charging the order : ${err?.response?.data?.message}`)
    })
  }

  const countQuantity = (cart) => {
    let qty = 0
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i]
      qty += element.quantity
    }
    return qty
  }

  const generateOption = (e) => {
    let el = cartRef.current[e]
    let r = []
    if(!el.selected.option || el.selected.option==="DEFAULT") return `Pas d'options`
    let opts = el.selected.option.split(`-`)
    for (let i = 0; i < opts.length; i++) {
      const element = opts[i]
      r.push(el.option[element].name)
    }
    return r.join(`-`)
  }

  const handleStatus = async (ev,e) => {
    ev.preventDefault()
    setSubmitLoadingRef(true)
    await Axios.post(config.api.url+`order/status`,{order:orderRef.current.id,status:e}).then(resp => {
      loadDatas()
      setPopUpRef(false)
      setSubmitLoadingRef(false)
      fri9g_notify.success(`Order status changed to ${e}`)
    }).catch(err => {
      fri9g_notify.error(`Error while updating the order : ${err?.response?.data?.message}`)
    })
  }

  return (<div className="page orders">
    <div className="page-action">
      {id?
        <button className={'button cancel-button '+(!__access['order']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/orders`)}} disabled={!__access['order']}><FontAwesomeIcon icon={faRightFromBracket} /> </button>
      :
        <div className="search">
          <input type="text" value={searchRef.current} onChange={(e) => {
            e.preventDefault()
            setSearchRef(e.target.value)
            if(e.target.value ==="") loadDatas()
          }}/>
          <div className="selector">
            {__selectorData.map((e,k)=>{
              if(e===selectorRef.current) return <h5 key={`${e}-${k}-selected`} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e}</h5>
              else return null
            })}
            {__selectorData.map((e,k)=>{
              if(e!==selectorRef.current) return <h5 key={`${e}-${k}-not-selected`} onClick={(ev) => {ev.preventDefault();setSelectorRef(e);ev.target.parentNode.classList.toggle('active')}}>{e}</h5>
              else return null
            })}
          </div>
          <button className="button" onClick={ev => getSearch(ev)}><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
        </div>
      }
      
    </div>
    {loadingRef.current ? <Loading/>: <>
      {id ? <div className="order-grid">
        <div className="order-cart">
          <div className="order-cart-title">
            <h2>Panier</h2>
          </div>
          <div className="order-cart-grid">
            {Object.keys(cartRef.current).map((e,k)=><div className="cart-product" key={`${cartRef.current[e].id}-${k}-cart-${cartRef.current[e].loaded}`}>
              {cartRef.current[e].loaded ? <>
              <div className="cart-product-picture">
                {cartRef.current[e].pictures.length > 0 && cartRef.current[e].pictures[0].file?<img className="picture" src={config.api.url+`images/src/${cartRef.current[e].pictures[0].file}`} alt={`cover of ${cartRef.current[e].id}`} />:<FontAwesomeIcon className="picture_default" icon={faImage} />}
              </div>
              <div className="cart-product-title">
                <h3>{Object.keys(cartRef.current[e].name).includes(`fr`) ? cartRef.current[e].name.fr : cartRef.current[e].name[Object.keys(cartRef.current[e].name)[0]]}</h3>
              </div>
              <div className="cart-product-option">
                <h4>{generateOption(e)}</h4>
              </div>
              <div className="cart-product-price">
                <h4>{`${cartRef.current[e].price} ${cartRef.current[e].currency.symbol} (${cartRef.current[e].currency.code})`}</h4>
              </div>
              <div className="cart-product-quantity">
                <h4>{`${cartRef.current[e].selected.quantity} unité${cartRef.current[e].selected.quantity>1 && `s`}`}</h4>
              </div>
              <div>
                <Link to={`/products/${cartRef.current[e].id}/view`} target="_blank">
                  <button className={"button edit-button"} onClick={(ev)=>{}}><FontAwesomeIcon icon={faEye} /></button>
                </Link>
              </div>
              </>:<Loading/>}
            </div>)}
          </div>
        </div>
        <div className="order-info">
          <h2>Informations</h2>
          <div className="order-info-general">
            {orderRef.current.loaded ? 
              <div className="order-info-grid">
                <h4>Montant :</h4><h5>{`${orderRef.current.amount} ${orderRef.current.currency.symbol} (${orderRef.current.currency.code})`}</h5>
                <h4>Identité :</h4><h5>{orderRef.current.identity}</h5>
                <h4>Adresse :</h4><h5>{orderRef.current.address}</h5>
                <h4>Ville :</h4><h5>{orderRef.current.city}</h5>
                <h4>Code Postal :</h4><h5>{orderRef.current.postcode}</h5>
                <h4>Country :</h4><h5>{orderRef.current.country.name}</h5>
                <h4>Session :</h4><h5>{orderRef.current.session}</h5>
                <h4>Statut :</h4><h5>{orderRef.current.status}</h5>
                <div className="status-action s2x">
                  {orderRef.current.status !== `CANCELED` && orderRef.current.status !== `COMPLETED` ? <button className="button edit-button" onClick={ev => {ev.preventDefault();setPopUpRef(`status`)}}>Modify statut</button> : <h3>Impossible de modifier le statut</h3>}
                </div>
              </div>
            :<Loading/>}
          </div>
          <div className="order-info-user">
            {orderRef.current.loaded ? 
              <div className="order-info-grid">
                <h3 className="s2x">Informations de l'utilisateur</h3>
                <h4>Username :</h4><h5>{orderRef.current.user.username}</h5>
                <h4>Email :</h4><h5>{orderRef.current.user.email}</h5>
              </div>
            :<Loading/>}
          </div>
        </div>
      </div>
      : <>
        {/* List part */}
        <div className="list-clipboard">
          <div className="list-title">
            <div>ID</div>
            <div>Date</div>
            <div>Products</div>
            <div>Amount</div>
            <div>Country</div>
            <div>Statut</div>
            <div></div>
          </div>
          {ordersRef.current.map((e,k) => <div className="list-entity" key={'list-'+k}>
            <div>{e.id}</div>
            <div>{new Date(e.createdAt).toUTCString()}</div>
            <div>{countQuantity(e.cart)}</div>
            <div>{`${e.amount} ${e.currency.symbol} (${e.currency.code})`}</div>
            <div>{`${e.country.name} - ${e.country.country.iso}`}</div>
            <div>{`${e.status}`}</div>
            <div>
              <button className="button edit-button" onClick={(ev)=>{ev.preventDefault();navigate(`/orders/${e.id}`)}}><FontAwesomeIcon icon={faEye} /></button>
            </div>
          </div>)}
        </div>
      </>}
      {popUpRef.current && <PopUpWindow className={`form category-${popUpRef.current}`} close={()=>{setPopUpRef(false)}}>
        {popUpRef.current === 'status' && <>
          <div className="order-grid">
            <div className="order-cart">
              <h2>Changement de status manuel</h2>
              <div>
                <button className={`edit-button ${submitLoadingRef.current && 'disabled'}`} disabled={submitLoadingRef.current} onClick={ev=>handleStatus(ev,`COMPLETED`)}>{submitLoadingRef.current ? <Loading/> : `Passer la commande en terminée`}</button>
              </div>
              {orderRef.current.status===`PENDING` && <>
                <div>
                  <button className={`cancel-button ${submitLoadingRef.current && 'disabled'}`} disabled={submitLoadingRef.current} onClick={ev=>handleStatus(ev,`PAID`)}>{submitLoadingRef.current ? <Loading/> : `Confirmer le paiement de la commande`}</button>
                </div>
                <div>
                  <button className={`delete-button ${submitLoadingRef.current && 'disabled'}`} disabled={submitLoadingRef.current} onClick={ev=>handleStatus(ev,`CANCELED`)}>{submitLoadingRef.current ? <Loading/> : `Annuler la commande`}</button>
                </div>
              </>}
            </div>
            <div className="order-info">
              <h2>Informations</h2>
              <div className="order-info-general">
                {orderRef.current.loaded ? 
                  <div className="order-info-grid">
                    <h4>Montant :</h4><h5>{`${orderRef.current.amount} ${orderRef.current.currency.symbol} (${orderRef.current.currency.code})`}</h5>
                    <h4>Identité :</h4><h5>{orderRef.current.identity}</h5>
                    <h4>Adresse :</h4><h5>{orderRef.current.address}</h5>
                    <h4>Ville :</h4><h5>{orderRef.current.city}</h5>
                    <h4>Code Postal :</h4><h5>{orderRef.current.postcode}</h5>
                    <h4>Session :</h4><h5>{orderRef.current.session}</h5>
                    <h4>Statut :</h4><h5>{orderRef.current.status}</h5>
                  </div>
                :<Loading/>}
              </div>
              <div className="order-info-user">
                {orderRef.current.loaded ? 
                  <div className="order-info-grid">
                    <h3 className="s2x">Informations de l'utilisateur</h3>
                    <h4>Username :</h4><h5>{orderRef.current.user.username}</h5>
                    <h4>Email :</h4><h5>{orderRef.current.user.email}</h5>
                  </div>
                :<Loading/>}
              </div>
            </div>
          </div>
        </>}
      </PopUpWindow>}
    </>}
  </div>)
}
export default Order