import { useEffect, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import authApi from "../services/authAPI"
import { fri9g_notify } from "fri9g-notification-react"
import config from "../config/api.config"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage, faPenToSquare, faSquarePlus } from "@fortawesome/free-regular-svg-icons"
import { faCheck, faCropSimple, faEye, faRightFromBracket, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons"
import PopUpWindow from "../components/PopUpWindow"
import app_config from "../config/app.config"
import CS from '../services/CS.js'

const Product = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const __access = authApi.get('access')
  const {id,action} = useParams()

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("Product")
    loadDatas()
  },[setRoute,id,action,props.creation])

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [submitLoading,setSubmitLoading] = useState(false)
  const submitLoadingRef = useRef(submitLoading)
  const setSubmitLoadingRef = (data) => {
    submitLoadingRef.current = data
    setSubmitLoading(data)
  }

  const [categories,setCategories] = useState([])
  const categoriesRef = useRef(categories)
  const setCategoriesRef = (data) => {
    categoriesRef.current = data
    setCategories(data)
  }

  const [currencies,setCurrencies] = useState([])
  const currenciesRef = useRef(currencies)
  const setCurrenciesRef = (data) => {
    currenciesRef.current = data
    setCurrencies(data)
  }

  const [locales,setLocales] = useState([])
  const localesRef = useRef(locales)
  const setLocalesRef = (data) => {
    localesRef.current = data
    setLocales(data)
  }

  const [products,setProducts] = useState([])
  const productsRef = useRef(products)
  const setProductsRef = (data) => {
    productsRef.current = data
    setProducts(data)
  }

  const [product,setProduct] = useState([])
  const productRef = useRef(product)
  const setProductRef = (data) => {
    productRef.current = data
    setProduct(data)
  }
  
  const [productEdit,setProductEdit] = useState(false)
  const productEditRef = useRef(productEdit)
  const setProductEditRef = (data) => {
    productEditRef.current = data
    setProductEdit(data)
  }

  const [selector,setSelector] = useState(null)
  const selectorRef = useRef(selector)
  const setSelectorRef = (data) => {
    selectorRef.current = data
    setSelector(data)
    loadDatas()
  }

  const [popUp,setPopUp] = useState(false)
  const popUpRef = useRef(popUp)
  const setPopUpRef = (data) => {
    popUpRef.current = data
    setPopUp(data)
  }

  const [productNew,setProductNew] = useState({
    "name":{
    },
    "description": {
    },
    "price":0,
    "currency":null,
    "option_active":false,
    "option":[],
    "category":null
})
  const productNewRef = useRef(productNew)
  const setProductNewRef = (data) => {
    productNewRef.current = data
    setProductNew(data)
  }

  const [picture,setPicture] = useState(null)
  const pictureRef = useRef(picture)
  const setPictureRef = (data) => {
    pictureRef.current = data
    setPicture(data)
  }

  const [drag,setDrag] = useState([])
  const dragRef = useRef(drag)
  const setDragRef= (data) => {
    dragRef.current = data
    setDrag(data)
  }

  const loadDatas = async () => {
    console.log('load')
    setLoadingRef(true)
    await Axios.get(config.api.url+'language?locale=fr&active=true').then(async res => {
      setLocalesRef(res.data)
      await Axios.get(config.api.url+'category/all').then(async resp => {
        setCategoriesRef(resp.data)
        if(props.creation) {
          await Axios.get(config.api.url+'currency?locale=fr&active=true').then(respp => {
            setCurrenciesRef(respp.data)
            setLoadingRef(false)
          })
        } else if(id) {
          await Axios.get(`${config.api.url}product/${id}?locale=fr`).then(async respp=>{
            let r = respp.data
            let ppc = productRef.current
            r.action = action
            if(ppc.tab) r.tab = ppc.tab
            else r.tab = 'infos'
            setProductRef(r)
            if(action==='edit') await Axios.get(config.api.url+'currency?locale=fr&active=true').then(resppp => {
              setCurrenciesRef(resppp.data)
              initEdit()
            })
            setLoadingRef(false)
          }).catch(err => {
            fri9g_notify.error(err?.response?.data?.message)
            navigate(`/products`)
          })
        } else {
          if(selectorRef.current) {
            await Axios.get(config.api.url+`products/${selectorRef.current}?locale=fr`).then(respp => {
              setProductsRef(respp.data)
              setLoadingRef(false)
            }).catch(err => {
              console.log(err)
              fri9g_notify.error(err)
            })
          } else {
            await Axios.get(config.api.url+'products?locale=fr').then(respp => {
              setProductsRef(respp.data)
              setLoadingRef(false)
            })
          }
        }
      })
    })
  }

  const handleDelete = async (e) => {
    setSubmitLoadingRef(true)
    await Axios.post(config.api.url+`product/delete?locale=fr`,{product:e}).then(resp => {
      fri9g_notify.success('product deleted')
      setPopUpRef(false)
      setSubmitLoadingRef(false)
      navigate(`/products`)
    }).catch(err => {
      setSubmitLoadingRef(false)
      fri9g_notify.error('Error while deleting the product : '+err.response.data.message)
    })
  }

  const handleCreate = async (e) => {
    setSubmitLoadingRef(true)
    await Axios.post(config.api.url+`product/new?locale=fr`,{...productNewRef.current}).then(resp => {
      fri9g_notify.success(`product created | id : ${resp.data.id}`)
      setProductNewRef({
        "name":{
        },
        "description": {
        },
        "price":0,
        "currency":null,
        "option_active":false,
        "option":[],
        "category":null
      })
      setSubmitLoadingRef(false)
      navigate(`/products/${resp.data.id}/view`)
    }).catch(err => {
      setSubmitLoadingRef(false)
      fri9g_notify.error(`Product creation failed : ${err?.response?.data?.code}`)
    })
  }
  
  const handleEdit = async () => {
    setSubmitLoadingRef(true)
    if(!__access['product_edit'] || productEditRef.current===false) return
    if(productRef.current.tab === `pictures`) {
      handlePicture(`order`)
    } else {
      let cc = {...productNewRef.current}
      cc.product = cc.id
      let nk = Object.keys(cc.name)
      for (let i = 0; i < nk.length; i++) {
        let el = nk[i]
        if(cc.name[el]==='') delete cc.name[el]
      }
      let dk = Object.keys(cc.description)
      for (let i = 0; i < dk.length; i++) {
        let el = dk[i]
        if(cc.description[el]==='') delete cc.description[el]
      }
      await Axios.post(config.api.url+`product/edit`,cc).then(resp => {
        setSubmitLoadingRef(false)
        fri9g_notify.success(`product edited`)
        navigate(`/products/${resp.data.product.id}/view`)
      }).catch(err => {
        setSubmitLoadingRef(false)
        fri9g_notify.error(`Product edit failed : ${err?.response?.data?.code}`)
      })
    }
  }

  const countStock = (e) => {
    var sum = 0
    for (let i = 0; i < Object.keys(e).length; i++) {
      let el = Object.keys(e)[i]
      sum = sum + e[el]
    }
    if(sum<0) return 0
    else return sum
  }

  const toggleProduct = async (k) => {
    let pp = [...productsRef.current]
    pp[k].loading = true 
    setProductsRef(pp)
    await Axios.post(config.api.url+'product/toggle',{product:pp[k].id}).then(res => {
      loadDatas()
      fri9g_notify.success('Product toggled')
    }).catch(err => {
      let pp = [...productsRef.current]
      pp[k].loading = false 
      setProductsRef(pp)
      fri9g_notify.error('Error while toggling the product')
    })
  }

  const initEdit = () => {
    setProductEditRef(false)
    let cc = {...productRef.current}
    if(cc.currency) cc.currency = cc.currency.code
    if(cc.category) cc.category = cc.category.id
    setProductNewRef(cc)
  }

  const resetEdit = () => {
    setProductNewRef({
      "name":{
      },
      "description": {
      },
      "price":0,
      "currency":null,
      "option_active":false,
      "option":[],
      "category":null
    })
    navigate(`/products`)
  }

  const toggleOptionStockDependant = (e,b) => {
    let p = {...productNewRef.current}
    for (let i = 0; i < Object.keys(p.option).length; i++) {
      let el = p.option[Object.keys(p.option)[i]]
      if (el.option===e) el[`stock_dependant`] = b
    }
    p.option_details[e][`stock-dependant`] = b
    setProductEditRef(true)
    setProductNewRef(p)
  }

  const handleChangeOption = (e,ev,type) => {
    let cc = {...productNewRef.current}
    switch(type) {
      case `type_code`:
        var selected_option = []
        var options = cc.option
        for (let i = 0; i < Object.keys(options).length; i++) {
          const element = options[Object.keys(options)[i]]
          if(element.option === e) selected_option.push(element.id)
        }
        let value = ev.target.value
        while(cc.option_details[value]) {
          value+=`_DOUBLE`
        }
        for (let i = 0; i < selected_option.length; i++) {
          const element = selected_option[i]
          cc.option[element].option = value
        }
        cc.option_details[value] = cc.option_details[e]
        delete cc.option_details[e]
        var index = cc.option_type.indexOf(e)
        cc.option_type[index] = value
        break
      case `type` :
        selected_option = []
        options = cc.option
        for (let i = 0; i < Object.keys(options).length; i++) {
          const element = options[Object.keys(options)[i]]
          if(element.option === e) selected_option.push(element.id)
        }
        for (let i = 0; i < selected_option.length; i++) {
          const element = selected_option[i]
          cc.option[element][`display-type`] = ev.target.value
          if(ev.target.value === `BOOLEAN`) cc.option[element].type = `BOOLEAN`
          else cc.option[element].type = `STRING` 
        }
        cc.option_details[e][`display-type`] = ev.target.value
        if(ev.target.value === `BOOLEAN`) cc.option_details[e].type = `BOOLEAN`
        else cc.option_details[e].type = `STRING` 
        break
      case `display_option`:
        cc.option[e][`display-option`] = ev.target.value
        break
      case `name`:
        cc.option[e].name = ev.target.value
        break
      case `add_option`:
        do {
          index = CS.slug(8)
        } while (cc.option[index])
        var option_type = cc.option_details[e]
        cc.option[index] = {
          id:index,
          'display-option':``,
          'display-type':option_type[`display-type`],
          name:``,
          option:e,
          stock_dependant:option_type[`stock-dependant`],
          type:option_type[`type-option`]
        }
        break
      case `add_option_type`:
        cc.option_type.push(``)
        cc.option_details[``] = {
          'display-type':`TEXT`,
          'stock-dependant':false,
          'type-option':`STRING`
        }
        break
      case `delete_option`:
        delete cc.option[e]
        break
      case `delete_option_type`:
        selected_option = []
        options = cc.option
        for (let i = 0; i < Object.keys(options).length; i++) {
          const element = options[Object.keys(options)[i]]
          if(element.option === e) selected_option.push(element.id)
        }
        for (let i = 0; i < selected_option.length; i++) {
          const element = selected_option[i]
          delete cc.option[element]
        }
        delete cc.option_details[e]
        index = cc.option_type.indexOf(e)
        cc.option_type.splice(index,1)
        break
      default:
    }
    setProductEditRef(true)
    setProductNewRef(cc)
  }

  const handleChangeStock = (e,ev) => {
    let cc = {...productNewRef.current}
    cc.stock[e] = parseInt(ev.target.value)
    setProductEditRef(true)
    setProductNewRef(cc)
  }

  const handlePicture = async (type,e=null) => {
    switch(type){
      case `add` :
        setSubmitLoadingRef(true)
        let body = new FormData()
        body.append(`file`,pictureRef.current)
        body.append(`product`,productNewRef.current.id)
        await Axios.post(config.api.url+`product/picture/new`,body,{
          headers:{
            "Content-Type": "multipart/form-data"
          }
        }).then(resp => {
          setPictureRef(null)
          setSubmitLoadingRef(false)
          setPopUpRef(false)
          fri9g_notify.success(`Picture added`)
          loadDatas()
        }).catch(err => {
          console.log(err)
          fri9g_notify.error(err?.data?.message)
          setSubmitLoadingRef(false)
        })
        break
      case `order`:
        await Axios.post(config.api.url+`product/picture/order`,{product:productNewRef.current.id,order:productNewRef.current.order_picture}).then(resp => {
          setSubmitLoadingRef(false)
          fri9g_notify.success(`pictures order edited`)
          navigate(`/products/${productNewRef.current.id}/view`)
        }).catch(err => {
          setSubmitLoadingRef(false)
          fri9g_notify.error(`pictures order failed : ${err?.response?.data?.code}`)
        })
        break
      case `delete`:
        let pics = productNewRef.current.pictures
        let cleanpics = productNewRef.current.pictures
        for (let i = 0; i < pics.length; i++) {
          const element = pics[i]
          if(element.id === e.id) {
            pics[i].loading = true
            break
          }
        }
        setProductNewRef({...productNewRef.current,pictures:pics})
        await Axios.post(config.api.url+`product/picture/delete`,{product:productNewRef.current.id,picture:e.id}).then(resp => {
          setSubmitLoadingRef(false)
          fri9g_notify.success(`picture deleted`)
          loadDatas()
        }).catch(err => {
          setSubmitLoadingRef(false)
          setProductNewRef({...productNewRef.current,pictures:cleanpics})
          fri9g_notify.error(`pictures deleted failed : ${err?.response?.data?.code}`)
        })
        break
      default:
    }
  }

  const handleDrag = (ev,type,e) => {
    let cc = {...dragRef.current}
    switch(type) {
      case `start`:
        cc.start = e
        cc.pnew = {...productNewRef.current}
        break
      case `end`:
        ev.preventDefault()
        if(cc.start && cc.over && cc.over.id!==cc.start.id) {
          let pc = cc.pnew
          let pics = pc.pictures
          let order = pc.order_picture
          if(cc.over.index === 0) {
            pics.splice(cc.start.index,1)
            pics.unshift(cc.start)
            order.splice(cc.start.index,1)
            order.unshift(cc.start.id)
          } else if(cc.over.index === pics.length -1) {
            pics.splice(cc.start.index,1)
            pics.push(cc.start)
            order.splice(cc.start.index,1)
            order.push(cc.start.id)
          } else {

            if(cc.over.index > cc.start.index) {
              pics = [].concat(pics.slice(0,cc.over.index+1),cc.start,pics.slice(cc.over.index+1))
              order = [].concat(order.slice(0,cc.over.index+1),cc.start.id,order.slice(cc.over.index+1))
              pics.splice(cc.start.index,1)
              order.splice(cc.start.index,1)
            } else {
              pics = [].concat(pics.slice(0,cc.over.index),cc.start,pics.slice(cc.over.index))
              order = [].concat(order.slice(0,cc.over.index),cc.start.id,order.slice(cc.over.index))
              pics.splice(cc.start.index+1,1)
              order.splice(cc.start.index+1,1)
            }
          }
          setProductNewRef({...pc,order_picture:order,pictures:pics})
          setProductEditRef(true)
          cc = {}
        }
        break
      case `over`:
        ev.preventDefault()
        cc.over = e
        break
      default:
    }
    setDragRef(cc)
  }
  
  return (<div className="page products">
  {loadingRef.current ? <Loading/>: <>
      {!id ? <>
        {props.creation ? <>
          <div className="page-action">
            <div className="page-action-nav">
              <div></div>
              <div className="s2x"><h2>Product creation</h2></div>
              <div></div>
              <div></div>
              <div></div>
              <button className={'button cancel-button '+(!__access['product']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();resetEdit();navigate(`/products`)}} disabled={!__access['product']}><FontAwesomeIcon icon={faRightFromBracket} /> </button>
            </div>
          </div>
          <div className="page-content form">
            <div className='infos'>
              <div className="content-block side-grid">
                <div className="content-block s2x">
                  <h3>Category</h3>
                  <div className="category-grid">
                    <div className="block">
                      <div className="selector">
                        {categoriesRef.current.map((e,k) => {
                          if(e.id === productNewRef.current.category) return <h5 key={'active-category-selector-'+e.id+'-'+k} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e.id + ' - ' + e.name[Object.keys(e.name)[0]]}</h5>
                          return null
                        })}
                        <h5 onClick={(ev) => {ev.preventDefault();if(productNewRef.current.category != null) {let pnc = {...productNewRef.current};pnc.category = null;setProductNewRef(pnc)} else {ev.target.parentNode.classList.toggle('active')} }}>No selection</h5>
                        {categoriesRef.current.map((e,k) => {
                          if(e.id !== productNewRef.current.category) return <h5 key={'category-selector-'+e.id+'-'+k} onClick={(ev) => {ev.preventDefault();let pnc = {...productNewRef.current};pnc.category = e.id;setProductNewRef(pnc);ev.target.parentNode.classList.remove('active')}}>{e.id + ' - ' + e.name[Object.keys(e.name)[0]]}</h5>
                          return null
                        })}
                      </div>
                    </div>
                    <div className="block">
                      <h4>Category names :</h4>
                      <div className="list-clipboard">
                        {productNewRef.current.category ? categoriesRef.current.map((e,k) => {
                          if(e.id === productNewRef.current.category) return Object.keys(e.name).length > 0 && Object.keys(e.name).map((el,kl) => <div key={`${k}-category-lang-${el}-${kl}`}><h4>{`${el} - ${e.name[el]}`}</h4></div>)
                          return null
                        }) : <h4>No category selected</h4>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-block">
                  <h3>Currency</h3>
                  <div className="selector">
                    {currenciesRef.current.map((e,k) => {
                      if(e.code === productNewRef.current.currency) return <h5 key={'active-currency-selector-'+e.code+'-'+k} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e.symbol + ' - ' + e.code}</h5>
                      return null
                    })}
                    <h5 onClick={(ev) => {ev.preventDefault();if(productNewRef.current.currency != null) {let pnc = {...productNewRef.current};pnc.currency = null;setProductNewRef(pnc)} ev.target.parentNode.classList.toggle('active') }}>No selection</h5>
                    {currenciesRef.current.map((e,k) => {
                      if(e.code !== productNewRef.current.currency) return <h5 key={'currency-selector-'+e.code+'-'+k} onClick={(ev) => {ev.preventDefault();let pnc = {...productNewRef.current};pnc.currency = e.code;setProductNewRef(pnc);ev.target.parentNode.classList.remove('active')}}>{e.symbol + ' - ' + e.code}</h5>
                      return null
                    })}
                  </div>
                </div>
                <div className="content-block">
                  <h3>Price</h3>
                  {/* <h4>{`${productRef.current.price}  ${productRef.current.currency.symbol}`}</h4> */}
                  <h4 className="input-grid"> <input type="number" value={productNewRef.current.price} step={0.01} onChange={(ev)=>{ev.preventDefault();let pnc = {...productNewRef.current} ;pnc.price = ev.target.value; setProductNewRef(pnc)}}/>
                  {productNewRef.current.currency ? currenciesRef.current.map((e,k) => {
                    if(e.code === productNewRef.current.currency) return <span key={'currency-selector-'+e.code+'-'+k}>{e.symbol}</span>
                    return null
                  }):<span> - </span>}
                  </h4>
                </div>
              </div>
              <div className="content-block main-grid">
                <div className="content-block">
                  <h3>Name</h3>
                  <div className="list-clipboard">
                    {localesRef.current.map((e,k) => <div className="clipboard-input" key={`${k}-name-lang-${e.iso}`}>
                      <h4>{e.iso}</h4>
                      <input type="text" value={productNewRef.current.name[e.iso]?productNewRef.current.name[e.iso]:''} onChange={(ev)=>{ev.preventDefault();let pnc = {...productNewRef.current} ;pnc.name[e.iso] = ev.target.value; setProductNewRef(pnc)}}/>
                    </div>)}
                    {/* {Object.keys(productRef.current.name).length > 0 && Object.keys(productRef.current.name).map((e,k) => <div key={`${k}-name-lang-${e}`}><h4>{`${e} - ${productRef.current.name[e]}`}</h4></div>)} */}
                  </div>
                </div>
                <div className="content-block">
                  <h3>Description</h3>
                  <div className="list-clipboard">
                    {localesRef.current.map((e,k) => <div className="clipboard-input" key={`${k}-description-lang-${e.iso}`}>
                      <h4>{e.iso}</h4>
                      <input type="text" value={productNewRef.current.description[e.iso]?productNewRef.current.description[e.iso]:''} onChange={(ev)=>{ev.preventDefault();let pnc = {...productNewRef.current} ;pnc.description[e.iso] = ev.target.value; setProductNewRef(pnc)}}/>
                    </div>)}
                    {/* {Object.keys(productRef.current.description).length > 0 && Object.keys(productRef.current.description).map((e,k) => <div key={`${k}-name-lang-${e}`}><h4>{`${e} - ${productRef.current.description[e]}`}</h4></div>)} */}
                  </div>
                </div>
              </div>
            </div>
            <div className="form-action">
              <button className={"button edit-button "+(!__access['product_edit']?'disabled-button':'')} onClick={(ev) => {ev.preventDefault();handleCreate()}}>CREATE</button>
            </div>
          </div>
        </>:<>
          <div className="page-action page-action-list">{console.log(productsRef.current)}
            <div className="selector">
              {categoriesRef.current.map((e,k) => {
                if(e.id === selectorRef.current) return <h5 key={'active-selector-'+e.id+'-'+k} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e.id + ' - ' + e.name[Object.keys(e.name)[0]]}</h5>
                return null
              })}
              <h5 onClick={(ev) => {ev.preventDefault();if(selectorRef.current != null) {setSelectorRef(null)} else {ev.target.parentNode.classList.toggle('active')} }}>No selection</h5>
              {categoriesRef.current.map((e,k) => {
                if(e.id !== selectorRef.current) return <h5 key={'selector-'+e.id+'-'+k} onClick={(ev) => {ev.preventDefault();setSelectorRef(e.id);ev.target.parentNode.classList.remove('active')}}>{e.id + ' - ' + e.name[Object.keys(e.name)[0]]}</h5>
                return null
              })}
            </div>
            <button className="button add-button" onClick={(e)=>{e.preventDefault();navigate('/products/new')}}>
              <FontAwesomeIcon icon={faSquarePlus} />
            </button>
          </div>
          <div className="list-clipboard">
            <div className="list-title">
              <div>Cover</div>
              <div>Name</div>
              <div>Price</div>
              <div>Stock</div>
              <div>Category</div>
              <div>Active</div>
              <div></div>
            </div>
          {productsRef.current.length > 0 && productsRef.current.map((e,k) => <div className="list-entity" key={'list-'+k} data-id={e.id}>
              {e.loading?<Loading/>:<>
                <div>{e.cover&&e.cover.file?<img className="picture" src={config.api.url+`images/s/${e.cover.file}`} alt={`cover of ${e.name}`} />:<FontAwesomeIcon className="picture_default" icon={faImage} />}</div>
                <div>{`${e.name}`}</div>
                <div>{`${e.price} ${e.currency.code} - ${e.currency.symbol}`}</div>
                <div>{Object.keys(e.stock).length===1&&e.stock.DEFAULT?e.stock.DEFAULT:countStock(e.stock)}</div>
                <div>{e.category.id ? `${e.category.id} | ${Object.keys(e.category.name)[0]} - ${e.category.name[Object.keys(e.category.name)[0]]}` : 'No Category'}</div>
                <div>{e.active?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} onClick={()=>{if(__access['product_edit'])toggleProduct(k)}} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} onClick={()=>{if(__access['product_edit'])toggleProduct(k)}} />}</div>
                <div>
                  <button className={"button edit-button "+(!__access['product']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/products/${e.id}/view`)}} disabled={!__access['product']}><FontAwesomeIcon icon={faEye} /></button>
                  <button className={"button edit-button "+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/products/${e.id}/edit`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faPenToSquare} /></button>
                </div>
              </>}
            </div> )}
          </div>
        </>}
      </>:<>
      {console.log(productNewRef.current)}
      {productRef.current.action === "edit" && <>
        <div className="page-action">
          <div className="page-action-nav">
            <div className={`page-action-nav-title ${productRef.current.tab==='infos' && 'active'}`} onClick={(e)=>{e.preventDefault();let p = {...productRef.current};p.tab='infos';setProductRef(p)}}><h2>Informations</h2></div>
            <div className={`page-action-nav-title ${productRef.current.tab==='options' && 'active'}`} onClick={(e)=>{e.preventDefault();let p = {...productRef.current};p.tab='options';setProductRef(p)}}><h2>Options</h2></div>
            <div className={`page-action-nav-title ${productRef.current.tab==='stocks' && 'active'}`} onClick={(e)=>{e.preventDefault();if(productEditRef.current===false) {let p = {...productRef.current};p.tab='stocks';setProductRef(p)} else {setPopUpRef('cancelToStocks')}}}><h2>Stocks</h2></div>
            <div className={`page-action-nav-title ${productRef.current.tab==='pictures' && 'active'}`} onClick={(e)=>{e.preventDefault();if(productEditRef.current===false){let p = {...productRef.current};p.tab='pictures';setProductRef(p)} else {setPopUpRef('cancelToPictures')}}}><h2>Pictures</h2></div>
            <button className={"button edit-button "+(!__access['product']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/products/${productRef.current.id}/view`)}} disabled={!__access['product']}><FontAwesomeIcon icon={faEye} /></button>
            <button className={'button cancel-button '+(!__access['product']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();!productEditRef.current ? resetEdit() : setPopUpRef('edit')}} disabled={!__access['product']}><FontAwesomeIcon icon={faRightFromBracket} /> </button>
            <button className={'button delete-button '+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setPopUpRef('delete')}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faTrashCan} /></button>
          </div>
          {/* <button className="button add-button" onClick={(e)=>{e.preventDefault();;setCategoryRef({
              action:'new',
              category:{name:{}},
              loading:false
            })}}>
            <FontAwesomeIcon icon={faSquarePlus} />
          </button> */}
        </div>
        <div className="page-content form">
          {productRef.current.tab === 'infos' && <div className='infos'>
              <div className="content-block side-grid">
                <div className="content-block s2x">
                  <h3>Category</h3>
                  <div className="category-grid">
                    <div className="block">
                      <div className="selector">
                        {categoriesRef.current.map((e,k) => {
                          if(e.id === productNewRef.current.category) return <h5 key={'active-category-selector-'+e.id+'-'+k} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e.id + ' - ' + e.name[Object.keys(e.name)[0]]}</h5>
                          return null
                        })}
                        <h5 onClick={(ev) => {ev.preventDefault();if(productNewRef.current.category != null) {let pnc = {...productNewRef.current};pnc.category = null;setProductNewRef(pnc);setProductEditRef(true)} ev.target.parentNode.classList.toggle('active') }}>No selection</h5>
                        {categoriesRef.current.map((e,k) => {
                          if(e.id !== productNewRef.current.category) return <h5 key={'category-selector-'+e.id+'-'+k} onClick={(ev) => {ev.preventDefault();let pnc = {...productNewRef.current};pnc.category = e.id;setProductNewRef(pnc);setProductEditRef(true);ev.target.parentNode.classList.remove('active')}}>{e.id + ' - ' + e.name[Object.keys(e.name)[0]]}</h5>
                          return null
                        })}
                      </div>
                    </div>
                    <div className="block">
                      <h4>Category names :</h4>
                      <div className="list-clipboard">
                        {productNewRef.current.category ? categoriesRef.current.map((e,k) => {
                          if(e.id === productNewRef.current.category) return Object.keys(e.name).length > 0 && Object.keys(e.name).map((el,kl) => <div key={`${k}-category-lang-${el}-${kl}`}><h4>{`${el} - ${e.name[el]}`}</h4></div>)
                          return null
                        }) : <h4>No category selected</h4>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-block">
                  <h3>Currency</h3>
                  <div className="selector">
                    {currenciesRef.current.map((e,k) => {
                      if(e.code === productNewRef.current.currency) return <h5 key={'active-currency-selector-'+e.code+'-'+k} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e.symbol + ' - ' + e.code}</h5>
                      return null
                    })}
                    <h5 onClick={(ev) => {ev.preventDefault();if(productNewRef.current.currency != null) {let pnc = {...productNewRef.current};pnc.currency = null;setProductNewRef(pnc);setProductEditRef(true)} ev.target.parentNode.classList.toggle('active') }}>No selection</h5>
                    {currenciesRef.current.map((e,k) => {
                      if(e.code !== productNewRef.current.currency) return <h5 key={'currency-selector-'+e.code+'-'+k} onClick={(ev) => {ev.preventDefault();let pnc = {...productNewRef.current};pnc.currency = e.code;setProductNewRef(pnc);setProductEditRef(true);ev.target.parentNode.classList.remove('active')}}>{e.symbol + ' - ' + e.code}</h5>
                      return null
                    })}
                  </div>
                </div>
                <div className="content-block">
                  <h3>Price</h3>
                  {/* <h4>{`${productRef.current.price}  ${productRef.current.currency.symbol}`}</h4> */}
                  <h4 className="input-grid"> <input type="number" value={productNewRef.current.price} step={0.01} onChange={(ev)=>{ev.preventDefault();let pnc = {...productNewRef.current} ;pnc.price = ev.target.value;setProductEditRef(true); setProductNewRef(pnc)}}/>
                  {productNewRef.current.currency ? currenciesRef.current.map((e,k) => {
                    if(e.code === productNewRef.current.currency) return <span key={'currency-selector-'+e.code+'-'+k}>{e.symbol}</span>
                    return null
                  }):<span> - </span>}
                  </h4>
                </div>
              </div>
              <div className="content-block main-grid">
                <div className="content-block">
                  <h3>Name</h3>
                  <div className="list-clipboard">
                    {localesRef.current.map((e,k) => <div className="clipboard-input" key={`${k}-name-lang-${e.iso}`}>
                      <h4>{e.iso}</h4>
                      <input type="text" value={productNewRef.current.name?.[e.iso]?productNewRef.current.name[e.iso]:''} onChange={(ev)=>{ev.preventDefault();let pnc = {...productNewRef.current} ;pnc.name[e.iso] = ev.target.value;setProductEditRef(true); setProductNewRef(pnc)}}/>
                    </div>)}
                  </div>
                </div>
                <div className="content-block">
                  <h3>Description</h3>
                  <div className="list-clipboard">
                    {localesRef.current.map((e,k) => <div className="clipboard-input" key={`${k}-description-lang-${e.iso}`}>
                      <h4>{e.iso}</h4>
                      <input type="text" value={productNewRef.current.description?.[e.iso]?productNewRef.current.description[e.iso]:''} onChange={(ev)=>{ev.preventDefault();let pnc = {...productNewRef.current} ;pnc.description[e.iso] = ev.target.value;setProductEditRef(true); setProductNewRef(pnc)}}/>
                    </div>)}
                    {/* {Object.keys(productRef.current.description).length > 0 && Object.keys(productRef.current.description).map((e,k) => <div key={`${k}-name-lang-${e}`}><h4>{`${e} - ${productRef.current.description[e]}`}</h4></div>)} */}
                  </div>
                </div>
              </div>
          </div>}
          {productRef.current.tab === 'options' && <div className='options'>
            <span>
              <h3>Options active : {productNewRef.current.option_active?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} onClick={()=>{let cc = {...productNewRef.current};cc.option_active=false;setProductNewRef(cc);setProductEditRef(true)}}/>:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} onClick={()=>{let cc = {...productNewRef.current};cc.option_active=true;setProductNewRef(cc);setProductEditRef(true)}}/>}</h3>
              <button className={"button add-button "+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();handleChangeOption(null,ev,`add_option_type`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faSquarePlus} /></button>
            </span>
            <div className="options-list">
              {productNewRef.current.option_type.length>0 ? productNewRef.current.option_type.map((e,k) => <div className="option-type" key={`${k}-options-type`}>
                <h3>Option type code : <input type="text" value={e} placeholder="Option title" onChange={ev => handleChangeOption(e,ev,`type_code`)}/></h3>
                <h4>Display type : <select onChange={(ev)=>{handleChangeOption(e,ev,'type')}} defaultValue={productNewRef.current.option_details[e]['display-type']}>
                  {app_config.option_type.map((el,k)=>
                    <option value={el} key={`option-type-${el}-${k}`} >{el}</option> //selected={productNewRef.current.option_details[e]['display-type']===el}
                  )}
                  </select></h4>
                <h4>Stock dependant : {productNewRef.current.option_details[e]['stock-dependant']?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} onClick={()=>{toggleOptionStockDependant(e,false)}}/>:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} onClick={()=>{toggleOptionStockDependant(e,true)}}/>}</h4>
                <span style={{display:'block',clear:'both'}}></span>
                <div className="option-available">
                  <h4>Option available :</h4> 
                  <span>
                    <button className={"button add-button "+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();handleChangeOption(e,ev,`add_option`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faSquarePlus} /></button>
                  </span>
                  <div className="list-clipboard">
                    {Object.keys(productNewRef.current.option).length>0 && Object.keys(productNewRef.current.option).map(index => {
                      if(productNewRef.current.option[index].option !== e) return null
                      return <div className="option-available-details" key={`option-${e}-${index}`}>
                        {productNewRef.current.option[index].option === e && <>
                          <h5> <input type="text" value={productNewRef.current.option[index].name} placeholder="Name Option" onChange={ev => handleChangeOption(index,ev,`name`)}/> </h5>
                          {productNewRef.current.option[index]['display-type'] === 'COLOR' && <h6 className="option-display-color">Display : <input type="text" value={productNewRef.current.option[index]['display-option']} onChange={ev => handleChangeOption(index,ev,`display_option`)}/> Preview :<span style={{backgroundColor:productNewRef.current.option[index]['display-option']}}/></h6>}
                          <span className="option-action">
                            <button className={'button delete-button '+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();handleChangeOption(index,ev,`delete_option`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faTrashCan} /></button>
                          </span>
                        </>}
                      </div>
                    })}
                  </div>
                  <div className="option-type-action">
                    <button className={'button delete-button '+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();handleChangeOption(e,ev,`delete_option_type`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faTrashCan} /></button>
                  </div>
                </div>
              </div>) : <h4>No option type available</h4>}
            </div>
          </div>}
          {productRef.current.tab === 'stocks' && <div className='stocks'>
            {Object.keys(productRef.current.stock).length > 1 ? <div className="options-list">
              {Object.keys(productRef.current.stock).map((e,k)=>{
                if(e==='DEFAULT') return null
                let keys = e.split('-')
                let text = ''
                for (let i = 0; i < keys.length; i++) {
                  let el = keys[i];
                  text+=productRef.current.option[el].name+'-'
                }
                if(text.length>0) text = text.slice(0,-1)
                return <div className="option-type" key={e+'-'+k+'-stock'}>
                  <h4>{text} : <input type="number" step={1} value={productRef.current.stock[e]} onChange={ev=>handleChangeStock(e,ev)}/> pieces</h4>
                </div>
              })}
            </div> : <h3>Default Stock : {productRef.current.stock.DEFAULT?productRef.current.stock.DEFAULT:'No stock'}</h3>}
          </div>}
          {productRef.current.tab === 'pictures' && <div className='pictures'>
            <button className={"button add-button "+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setPopUpRef(`addPicture`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faSquarePlus} /></button>
            {productNewRef.current.pictures.length > 0 ? <div className="options-list">
              {productNewRef.current.pictures.map((e,k)=> <div className="option-type" key={e.id+'-'+k+`-pictures`} onDragStart={ev => handleDrag(ev,`start`,{...e,index:k})} onDragOver={ev => handleDrag(ev,`over`,{...e,index:k})} onDragEnd={ev => handleDrag(ev,`end`,{...e,index:k})} draggable={true}>
                {e.loading?<Loading/>:<>
                  <img className="picture" src={config.api.url+`images/src/${e.file}`} alt={`cover of ${e.id}`} />
                  <div className="option-type-action">
                    <button className={'button delete-button '+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();handlePicture(`delete`,e)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faTrashCan} /></button>
                  </div>
                </>}
              </div>)}
            </div>:<h4>No pictures</h4>}
          </div>}
          <div className="form-action">
            <button className={"button edit-button "+(!__access['product_edit'] || productEditRef.current===false ?'disabled-button':'')} onClick={(ev) => {ev.preventDefault();handleEdit()}}>EDIT</button>
          </div>
        </div>
      </>}
      {productRef.current.action === "view" && <>
        <div className="page-action">
          <div className="page-action-nav">
            <div className={`page-action-nav-title ${productRef.current.tab==='infos' && 'active'}`} onClick={(e)=>{e.preventDefault();let p = {...productRef.current};p.tab='infos';setProductRef(p)}}><h2>Informations</h2></div>
            <div className={`page-action-nav-title ${productRef.current.tab==='options' && 'active'}`} onClick={(e)=>{e.preventDefault();let p = {...productRef.current};p.tab='options';setProductRef(p)}}><h2>Options</h2></div>
            <div className={`page-action-nav-title ${productRef.current.tab==='stocks' && 'active'}`} onClick={(e)=>{e.preventDefault();let p = {...productRef.current};p.tab='stocks';setProductRef(p)}}><h2>Stocks</h2></div>
            <div className={`page-action-nav-title ${productRef.current.tab==='pictures' && 'active'}`} onClick={(e)=>{e.preventDefault();let p = {...productRef.current};p.tab='pictures';setProductRef(p)}}><h2>Pictures</h2></div>
            <button className={"button edit-button "+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/products/${productRef.current.id}/edit`)}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faPenToSquare} /></button>
            <button className={'button cancel-button '+(!__access['product']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/products`)}} disabled={!__access['product']}><FontAwesomeIcon icon={faRightFromBracket} /> </button>
            <button className={'button delete-button '+(!__access['product_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setPopUpRef('delete')}} disabled={!__access['product_edit']}><FontAwesomeIcon icon={faTrashCan} /></button>
          </div>
          {/* <button className="button add-button" onClick={(e)=>{e.preventDefault();;setCategoryRef({
              action:'new',
              category:{name:{}},
              loading:false
            })}}>
            <FontAwesomeIcon icon={faSquarePlus} />
          </button> */}
        </div>
        <div className="page-content">
          {productRef.current.tab === 'infos' && <div className='infos'>
            <div className="content-block side-grid">
              <div className="content-block s2x">
                <h3>Category</h3>
                <div className="category-grid">
                  <div className="block">
                    <h4>ID : {productRef.current.category.id?productRef.current.category.id:'No category'}</h4>
                  </div>
                  <div className="block">
                    {!productRef.current.category.name && 'No category name'}
                    {productRef.current.category.name && Object.keys(productRef.current.category.name).length > 0 && Object.keys(productRef.current.category.name).map((e,k) => <div key={`${k}-category-lang-${e}`}><h4>{`${e} - ${productRef.current.category.name[e]}`}</h4></div>)}
                  </div>
                </div>
              </div>
              <div className="content-block">
                <h3>Currency</h3>
                <h4>{`${productRef.current.currency.code} - ${productRef.current.currency.symbol}`}</h4>
              </div>
              <div className="content-block">
                <h3>Price</h3>
                <h4>{`${productRef.current.price}  ${productRef.current.currency.symbol}`}</h4>
              </div>
            </div>
            <div className="content-block main-grid">
              <div className="content-block">
                <h3>Name</h3>
                <div className="list-clipboard">
                  {Object.keys(productRef.current.name).length > 0 && Object.keys(productRef.current.name).map((e,k) => <div key={`${k}-name-lang-${e}`}><h4>{`${e} - ${productRef.current.name[e]}`}</h4></div>)}
                </div>
              </div>
              <div className="content-block">
                <h3>Description</h3>
                <div className="list-clipboard">
                  {Object.keys(productRef.current.description).length > 0 && Object.keys(productRef.current.description).map((e,k) => <div key={`${k}-name-lang-${e}`}><h4>{`${e} - ${productRef.current.description[e]}`}</h4></div>)}
                </div>
              </div>
            </div>
          </div>}
          {productRef.current.tab === 'options' && <div className='options'>
            <h3>Options active : {productRef.current.option_active?<FontAwesomeIcon className="font-icon green-font" icon={faCheck}/>:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} />}</h3>
            <div className="options-list">
              {productRef.current.option_type.length>0 ? productRef.current.option_type.map((e,k) => <div className="option-type" key={`${k}-options-type-${e}`}>
                <h3>Option type code : {e}</h3>
                <h4>Display type : {productRef.current.option_details[e]['display-type']}</h4>
                <h4>Stock dependant : {productRef.current.option_details[e]['stock-dependant']?<FontAwesomeIcon className="font-icon green-font" icon={faCheck}/>:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} />}</h4>
                <div className="option-available">
                  <h4>Option available :</h4>
                  <div className="list-clipboard">
                    {Object.keys(productRef.current.option).length>0 && Object.keys(productRef.current.option).map(index => {
                      if(productRef.current.option[index].option !== e) return null
                      return <div className="option-available-details" key={`option-${e}-${index}`}>
                        {productRef.current.option[index].option === e && <>
                          <h5>{productRef.current.option[index].name}</h5>
                          {productRef.current.option[index]['display-type'] === 'COLOR' && <h6 className="option-display-color">Display : <span style={{backgroundColor:productRef.current.option[index]['display-option']}}/></h6>}
                        </>}
                      </div>
                    })}
                  </div>
                </div>
              </div>) : <h4>No option type available</h4>}
            </div>
          </div>}
          {productRef.current.tab === 'stocks' && <div className='stocks'>
            {Object.keys(productRef.current.stock).length > 1 ? <div className="options-list">
              {Object.keys(productRef.current.stock).map((e,k)=>{
                if(e==='DEFAULT') return null
                let keys = e.split('-')
                let text = ''
                for (let i = 0; i < keys.length; i++) {
                  let el = keys[i];
                  text+=productRef.current.option[el].name+'-'
                }
                if(text.length>0) text = text.slice(0,-1)
                return <div className="option-type" key={e+'-'+k+'-stock'}>
                  <h4>{`${text} : ${productRef.current.stock[e]} pieces`}</h4>
                </div>
              })}
            </div> : <h3>Default Stock : {productRef.current.stock.DEFAULT?productRef.current.stock.DEFAULT:'No stock'}</h3>}
          </div>}
          {productRef.current.tab === 'pictures' && <div className='pictures'>
            {productRef.current.pictures.length > 0 ? <div className="options-list">
              {productRef.current.pictures.map((e,k)=> <div className="option-type" key={e+'-'+k+'-pictures'}>
                <img className="picture" src={config.api.url+`images/src/${e.file}`} alt={`cover of ${e.id}`} />
              </div>)}
            </div>:<h4>No pictures</h4>}
          </div>}
        </div>
      </>}
      {popUpRef.current && <PopUpWindow className={`form category-${popUpRef.current}`} close={()=>{setPopUpRef(false);pictureRef.current && setPictureRef(null)}}>
        {/* Delete Panel */}
        {popUpRef.current === 'delete' && <>
          <div className="popup-window-panel">
            Confirm delete of product {`${Object.keys(productRef.current.name)[0]} - ${productRef.current.name[Object.keys(productRef.current.name)[0]]}`}
            <div className="action-grid">
              <button className={`edit-button ${submitLoadingRef.current && 'disabled'}`} disabled={submitLoadingRef.current} onClick={(e)=>{e.preventDefault();handleDelete(productRef.current.id)}}>{submitLoadingRef.current ? <Loading/> : `Submit`}</button>
              <button className="delete-button" onClick={()=>{setPopUpRef(false)}}>Cancel</button>
            </div>
          </div>
        </>}

        {popUpRef.current === 'edit' && <>
          <div className="popup-window-panel">
            Confirm cancel of editing for product {`${Object.keys(productRef.current.name)[0]} - ${productRef.current.name[Object.keys(productRef.current.name)[0]]}`}
            <div className="action-grid">
              <button className="edit-button" onClick={(e)=>{e.preventDefault();setPopUpRef(false);resetEdit()}}>Cancel</button>
              <button className="delete-button" onClick={()=>{setPopUpRef(false)}}>Continue</button>
            </div>
          </div>
        </>}

        {popUpRef.current === 'cancelToStocks' && <>
          <div className="popup-window-panel">
            Confirm cancel of editing for product {`${Object.keys(productRef.current.name)[0]} - ${productRef.current.name[Object.keys(productRef.current.name)[0]]}`}
            <div className="action-grid">
              <button className="edit-button" onClick={(e)=>{e.preventDefault();setPopUpRef(false);loadDatas();let p = {...productRef.current};p.tab='stocks';setProductRef(p)}}>Continue to stock</button>
              <button className="delete-button" onClick={()=>{setPopUpRef(false)}}>Cancel</button>
            </div>
          </div>
        </>}

        {popUpRef.current === 'cancelToPictures' && <>
          <div className="popup-window-panel">
            Confirm cancel of editing for product {`${Object.keys(productRef.current.name)[0]} - ${productRef.current.name[Object.keys(productRef.current.name)[0]]}`}
            <div className="action-grid">
              <button className="edit-button" onClick={(e)=>{e.preventDefault();setPopUpRef(false);loadDatas();let p = {...productRef.current};p.tab='pictures';setProductRef(p)}}>Continue to pictures</button>
              <button className="delete-button" onClick={()=>{setPopUpRef(false)}}>Cancel</button>
            </div>
          </div>
        </>}

        {popUpRef.current === `addPicture` && <>
          <div className="popup-window-panel">
            <h2>Add picture for product {productNewRef.current.id} - {productNewRef.current.name[Object.keys(productNewRef.current.name)[0]]}</h2>
            <input type="file" onChange={ev => {setPictureRef(ev.target.files[0])}}/>
            <div className="picture-preview">
              {pictureRef.current ? <img src={URL.createObjectURL(pictureRef.current)} alt="file preview" />:<FontAwesomeIcon className="picture_default" icon={faImage} />}
            </div>
            <div className="action-grid">
              <button className={`edit-button ${submitLoadingRef.current && 'disabled'}`} disabled={submitLoadingRef.current} onClick={(e)=>{e.preventDefault();handlePicture(`add`)}}>{submitLoadingRef.current ? <Loading/> : `Add picture`}</button>
              <button className="delete-button" onClick={()=>{setPopUpRef(false);setPictureRef(null)}}>Cancel</button>
            </div>
          </div>
        </>}
      </PopUpWindow>}
    </>}
    </>}
</div>)
}
export default Product