import { useEffect } from "react"
import config from './../config/api.config'
import { useState } from "react"
import { useRef } from "react"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquarePlus } from "@fortawesome/free-solid-svg-icons"
import { fri9g_notify } from "fri9g-notification-react"
import authApi from "../services/authAPI"
import { useNavigate } from "react-router-dom"

const Language = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("Language")
    loadDatas()
  },[setRoute])

  const [currencies,setCurrencies] = useState([])
  const currenciesRef = useRef(currencies)
  const setCurrenciesRef = (data) => {
    currenciesRef.current = data
    setCurrencies(data)
  }

  const [search,setSearch] = useState('')
  const searchRef = useRef(search)
  const setSearchRef = (data) => {
    searchRef.current = data
    setSearch(data)
  }

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [loadingObj,setLoadingObj] = useState(null)
  const loadingObjRef = useRef(loadingObj)
  const setLoadingObjRef = (data) => {
    loadingObjRef.current = data
    setLoadingObj(data)
  }

  const changeCurrency = async (cur) => {
    if(loadingObjRef.current !== null) return
    setLoadingObjRef(cur)
    Axios.post(config.api.url+'language/toggle',{language:cur}).then(resp => {
      fri9g_notify.success("Language toggled : "+cur)
      loadDatas(()=>{
        setLoadingObjRef(null)
      })
    }).catch(e=>{
      fri9g_notify.error("Language toggle failed : "+cur)
      setLoadingObjRef(null)
    })
  }

  const loadDatas = (f = ()=>{return null}) => {
    Axios.get(config.api.url+'language?locale=fr').then(resp => {
      setCurrenciesRef(resp.data)
      setLoadingRef(false)
      f()
    })
  }

  return (<div className="page language">
    {
      loading ? <Loading/> :
      <>
        <div className="page-action">
          <div className="search">
            <h2>Recherche : </h2>
            <input type="text" value={searchRef.current} onChange={(e) => {
              e.preventDefault()
              setSearchRef(e.target.value)
            }}/>
          </div>
        </div>
        <div className={"list-clipboard "}>
          { currencies.map((e,k)=> {
            return e.iso.match(new RegExp(searchRef.current,'i')) || e.name.match(new RegExp(searchRef.current,'i')) ? <div className="list-entity" key={k} data-id={e.id}>
              <div className={"list-entity-field list-entity-field-symbol"} key={k+"-symbol"}>
                <h2>{e.iso + ' - ' + e.name}</h2>
              </div>
              <div className={"list-entity-field list-entity-field-boolean"} key={k+"-active"}>
                <h4>
                  {loadingObjRef.current===e.iso  ? <Loading/> : <input type="checkbox" defaultChecked={e.active} readOnly onClick={(g)=>{g.preventDefault();changeCurrency(e.iso)}} /> }
                </h4>
              </div>
            </div> : null})
          }
        </div>
      </>
    }
  
  </div>)
}
export default Language