import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import authApi from "../services/authAPI"
import { fri9g_notify } from "fri9g-notification-react"
import config from "../config/api.config"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare, faSquarePlus } from "@fortawesome/free-regular-svg-icons"
import { faCheck, faEye, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons"
import PopUpWindow from "../components/PopUpWindow"

const User = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const __access = authApi.get('access')

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("User")
    loadDatas()
  },[setRoute])

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [users,setUsers] = useState([])
  const usersRef = useRef(users)
  const setUsersRef = (data) => {
    usersRef.current = data
    setUsers(data)
  }

  const [user,setUser] = useState([])
  const userRef = useRef(user)
  const setUserRef = (data) => {
    userRef.current = data
    setUser(data)
  }


  const loadDatas = async () => {
    setLoadingRef(true)
    await Axios.get(config.api.url+'users').then(resp => { 
      setUsersRef(resp.data.users)
      setLoadingRef(false)
    })
  }

  const loadPanelDatas = async () => {
    var url = config.api.url+`user/${userRef.current.user.id}/${userRef.current.tab}?locale=fr`
    switch(userRef.current.action) {
      case 'edit':
        url = config.api.url+`user/${userRef.current.user.id}?locale=fr`
        await Axios.get(url).then(resp => {
          setUserRef({...userRef.current,user:resp.data})
        })
        url = config.api.url+'roles'
        break;
      case 'view':
      default:
    }
    await Axios.get(url).then(resp=>{
      setUserRef({...userRef.current,datas:resp.data,loading:false})
    })
  }

  const submitData = async () => {
    var url = config.api.url+`user/${userRef.current.user.id}`
    var body = {...userRef.current.user}
    switch(userRef.current.action) {
      case 'edit': 
        if(userRef.current.tab === 'role') {
          if(!__access.role) {
            fri9g_notify.error('No access')
            return
          }
          url = url+'/role'
          body.role = body.role.id
        }
        await Axios.post(url,body).then(resp=>{
          setUserRef({loading:false})
          loadDatas()
          fri9g_notify.success('User edited')
        }).catch(err=>{
          fri9g_notify.error('Edit failed : '+err.message)
        })
        break
      default:
    }
    
  }

  return (<div className="page users">
    {loadingRef.current ? <Loading/>: <>
      <div className="page-action">

      </div>
      <div className="list-clipboard">
        <div className="list-title">
          <div>Username</div>
          <div>Email</div>
          <div>Role</div>
          <div>Newsletter</div>
          <div>Verified</div>
          <div></div>
        </div>
      
      {usersRef.current.length > 0 && usersRef.current.map((e,k) => <div className="list-entity" key={'list-'+k}>
          <div>{e.username}</div>
          <div>{e.email}</div>
          <div>{e.role.name}</div>
          <div>{e.newsletter?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} />}</div>
          <div>{e.verified?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} />}</div>
          <div>
            <button className={"button edit-button "+(!__access['user']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setUserRef({action:'view',user:e,loading:false,tab:'info'})}} disabled={!__access['user']}><FontAwesomeIcon icon={faEye} /></button>
            <button className={"button edit-button "+(!__access['user_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setUserRef({action:'edit',user:e,loading:true,tab:'info'});loadPanelDatas()}} disabled={!__access['user_edit']}><FontAwesomeIcon icon={faPenToSquare} /></button>
            {/* <button className={'delete-button '+(!__access['user_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setUserRef({action:'delete',user:e,loading:true})}} disabled={!__access['user_edit']}><FontAwesomeIcon icon={faTrashCan} /></button> */}
          </div>
        </div> )}
      </div>
      {/* window form part */}
      {userRef.current.user && 
        <PopUpWindow className={`form user-${userRef.current.action}`} close={()=>{setUserRef({loading:false})}}>
          {/* View Panel */}
          {userRef.current.action === 'view' && <>
            <div className="popup-window-nav">
              <div className={'popup-window-nav-title '+(userRef.current.tab==='info' && 'active')} onClick={(ev)=>{ev.preventDefault();if(userRef.current.tab !== 'info') {setUserRef({...userRef.current,loading:false,tab:'info'})}}}><h3>Info</h3></div>
              <div className={'popup-window-nav-title '+(userRef.current.tab==='orders' && 'active')} onClick={(ev)=>{ev.preventDefault();if(userRef.current.tab !== 'orders') {setUserRef({...userRef.current,loading:true,tab:'orders'});loadPanelDatas()}}}><h3>Orders</h3></div>
              <div className={'popup-window-nav-title '+(userRef.current.tab==='supports' && 'active')} onClick={(ev)=>{ev.preventDefault();if(userRef.current.tab !== 'supports') {setUserRef({...userRef.current,loading:true,tab:'supports'});loadPanelDatas()}}}><h3>Supports</h3></div>
            </div>
            <div className="popup-window-panel">
              {userRef.current.loading?<Loading/>:<>
                {userRef.current.tab === 'info' && <>
                  <div className="info-grid panel-grid">
                    <div className="block">
                      <h3>Username</h3>
                      <h4>{userRef.current.user.username}</h4>
                    </div>
                    <div className="block grid-2y">  
                      <h3>Role</h3>
                      <h4>{userRef.current.user.role.name}</h4>
                    </div>
                    <div className="block">
                      <h3>Email</h3>
                      <h4>{userRef.current.user.email}</h4>
                    </div>
                    <div className="block">
                      <h3>Newsletter</h3>
                      <h4>{userRef.current.user.newsletter?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} />}</h4>
                    </div>
                    <div className="block">
                      <h3>Verified</h3>
                      <h4>{userRef.current.user.verified?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} />}</h4>
                    </div>
                  </div>
                </>}
                {userRef.current.tab === 'orders' && <>
                  <div className="orders-grid panel-grid">
                    <div className="block title">
                      <div className="line-block"><h4>ID</h4></div>
                      <div className="line-block"><h4>Quantity</h4></div>
                      <div className="line-block"><h4>Amount</h4></div>
                      <div className="line-block"><h4>Currency</h4></div>
                      <div className="line-block"></div>
                    </div>
                    {userRef.current.datas.order.map((e,k)=><div className="block" key={k+'-'+e.id}>
                      <div className="line-block">{e.id}</div>
                      <div className="line-block">{(e.cart).reduce((a,b)=>a+b.quantity,0)} items</div>
                      <div className="line-block">{e.amount}</div>
                      <div className="line-block">{e.currency.code + ' - ' + e.currency.symbol}</div>
                      <div className="line-block"><Link target="_blank" to={`/orders/${e.id}`}><button className={"edit-button small-button "+(!__access['order']?'disabled-button':'')}><FontAwesomeIcon icon={faEye} /></button></Link></div>
                    </div>)}
                  </div>
                </>}
                {userRef.current.tab === 'supports' && <>
                  {console.log(__access)}
                  <div className="supports-grid panel-grid">
                    <div className="block title">
                      <div className="line-block"><h4>ID</h4></div>
                      <div className="line-block"><h4>Type</h4></div>
                      <div className="line-block"><h4>Subject</h4></div>
                      <div className="line-block"><h4>Status</h4></div>
                      <div className="line-block"></div>
                    </div>
                    {userRef.current.datas.support_request.map((e,k)=><div className="block" key={k+'-'+e.id}>
                      <div className="line-block">{e.id}</div>
                      <div className="line-block">{e.type}</div>
                      <div className="line-block">{e.subject}</div>
                      <div className="line-block">{e.status}</div>
                      <div className="line-block"><Link target="_blank" to={`/support/${e.id}`}><button className={"edit-button small-button "+(!__access['support']?'disabled-button':'')}><FontAwesomeIcon icon={faEye} /></button></Link></div>
                    </div>)}
                  </div>
                </>}
              </>}
            </div>
          </>}

          {/* Edit Panel */}
          {userRef.current.action === 'edit' && <>
            {__access.role && 
            <div className="popup-window-nav">
              <div className={'popup-window-nav-title '+(userRef.current.tab==='info' && 'active')} onClick={(ev)=>{ev.preventDefault();if(userRef.current.tab !== 'info') {setUserRef({...userRef.current,loading:false,tab:'info'})}}}><h3>Info</h3></div>
              <div className={'popup-window-nav-title '+(userRef.current.tab==='role' && 'active')} onClick={(ev)=>{ev.preventDefault();if(userRef.current.tab !== 'role') {setUserRef({...userRef.current,loading:false,tab:'role'})}}}><h3>Role</h3></div>
            </div>}
            <div className="popup-window-panel">
              {userRef.current.loading?<Loading/>:
              <div className="edit-grid panel-grid">
                {userRef.current.tab === 'info' && <>
                  <div className="block">
                    <h3>Username</h3>
                    <h4><input type="text" value={userRef.current.user.username} onChange={(e)=>{e.preventDefault();let u = {...userRef.current};u.user.username=e.target.value;setUserRef(u)}}/></h4>
                  </div>
                  <div className="block">
                    <h3>Email</h3>
                    <h4><input type="text" value={userRef.current.user.email} onChange={(e)=>{e.preventDefault();let u = {...userRef.current};u.user.email=e.target.value;setUserRef(u)}}/></h4>
                  </div>
                  <div className="block">
                    <h3>Newsletter</h3>
                    <h4>{userRef.current.user.newsletter?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} onClick={(e)=>{e.preventDefault();let u = {...userRef.current};u.user.newsletter=false;setUserRef(u)}}/>:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} onClick={(e)=>{e.preventDefault();let u = {...userRef.current};u.user.newsletter=true;setUserRef(u)}} />}</h4>
                  </div>
                  <div className="block">
                    <h3>Verified</h3>
                    <h4>{userRef.current.user.verified?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} onClick={(e)=>{e.preventDefault();let u = {...userRef.current};u.user.verified=false;setUserRef(u)}} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} onClick={(e)=>{e.preventDefault();let u = {...userRef.current};u.user.verified=true;setUserRef(u)}} />}</h4>
                  </div>
                </>}
                {userRef.current.tab === 'role' && <div className="block grid-2x">  
                  <h3>Role</h3>
                  {console.log(userRef.current)}
                  <h4>{authApi.get('role_weight') > userRef.current.user.role.weight ? <select value={userRef.current.user.role.id} onChange={(e)=>{e.preventDefault();let u = {...userRef.current};let r = userRef.current.datas.role.find(el=>el.id === parseInt(e.target.value));u.user.role=r;setUserRef(u)}}>
                    {userRef.current.datas.role.map((e,k)=><option value={e.id} key={k+'-'+e.id}>{e.name}</option>)}
                  </select> :userRef.current.user.role.name}</h4>
                </div>}
              </div>}
              <div className="action-grid">
                <button className="edit-button" onClick={(e)=>{e.preventDefault();submitData()}}>Submit</button>
                <button className="delete-button" onClick={()=>{setUserRef({loading:false})}}>Cancel</button>
              </div>
            </div>
          </>}

          {/* Delete Panel
          {userRef.current.action === 'delete' && <>
            <div className="popup-window-panel">
              Confirm delete of user {`${userRef.current.user.id} - ${userRef.current.user.username}`}
              <div className="action-grid">
                <button className="edit-button" onClick={(e)=>{e.preventDefault();submitData()}}>Submit</button>
                <button className="delete-button" onClick={()=>{setUserRef({loading:false})}}>Cancel</button>
              </div>
            </div>
          </>} */}
        </PopUpWindow>}
      </>}
  </div>)
}
export default User