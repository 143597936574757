import { faCircleXmark } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const PopUpWindow = (props) => {

  return <div className={"popup-background "+props.className}>
    <div className="popup-close">
      <FontAwesomeIcon icon={faCircleXmark} onClick={props.close}/>
    </div>
    <div className="popup-window">
      {props.children}
    </div>
  </div>

}

export default PopUpWindow