import { useEffect, useRef, useState } from "react"
import PopUpWindow from "../components/PopUpWindow"
import authApi from "../services/authAPI"
import { Link, useNavigate, useParams } from "react-router-dom"
import { fri9g_notify } from "fri9g-notification-react"
import config from "../config/api.config"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage, faMessage } from "@fortawesome/free-regular-svg-icons"
import { faEye, faMagnifyingGlass, faRightFromBracket } from "@fortawesome/free-solid-svg-icons"

const Support = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const __access = authApi.get('access')
  const {id} = useParams()

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("Support")
    setLoadingRef(true)
    loadDatas()
  },[setRoute,id])

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [submitLoading,setSubmitLoading] = useState(false)
  const submitLoadingRef = useRef(submitLoading)
  const setSubmitLoadingRef = (data) => {
    submitLoadingRef.current = data
    setSubmitLoading(data)
  }

  const [search,setSearch] = useState('')
  const searchRef = useRef(search)
  const setSearchRef = (data) => {
    searchRef.current = data
    setSearch(data)
  }

  const __selectorData = [`id`,`user`]
  const [selector,setSelector] = useState(`id`)
  const selectorRef = useRef(selector)
  const setSelectorRef = (data) => {
    selectorRef.current = data
    setSelector(data)
  }

  const [supReqs,setSupReqs] = useState([])
  const supReqsRef = useRef(supReqs)
  const setSupReqsRef = (data) => {
    supReqsRef.current = data
    setSupReqs(data)
  }

  const [supReq,setSupReq] = useState([])
  const supReqRef = useRef(supReq)
  const setSupReqRef = (data) => {
    supReqRef.current = data
    setSupReq(data)
  }

  const [scroll,setScroll] = useState(0)
  const scrollRef = useRef(scroll)
  const setScrollRef = (data) => {
    scrollRef.current = data
    setScroll(data)
  }

  const [answer,setAnswer] = useState(``)
  const answerRef = useRef(answer)
  const setAnswerRef = (data) => {
    answerRef.current = data
    setAnswer(data)
  }

  const [popUp,setPopUp] = useState(false)
  const popUpRef = useRef(popUp)
  const setPopUpRef = (data) => {
    popUpRef.current = data
    setPopUp(data)
  }

  const loadDatas = async () => {
    setLoadingRef(true)
    await Axios.get(`${config.api.url}user/supports${id ? ('/'+id):''}?locale=fr`).then(resp => {
      if(id) {
        resp.data.message = resp.data.message.reverse()
        setSupReqRef(resp.data)
        setLoadingRef(false)
        setScrollRef(resp.data.message.length)
        setSupReqsRef([])
      }
      else {
        setSupReqsRef(resp.data.support_request)
        setSupReqRef([])
        setScrollRef(0)
      }
      setLoadingRef(false)
    }).catch(err =>{
      fri9g_notify.error(`Error while charging the support request : ${err?.response?.data?.message}`)
    })
  }

  const getSearch = async () => {
    setLoadingRef(true)
    await Axios.get(config.api.url+`supports/search?locale=fr&search=${searchRef.current}&params=${selectorRef.current}`).then(resp => {
      setSupReqsRef(resp.data)
      setLoadingRef(false)
    }).catch(err =>{
      fri9g_notify.error(`Error while charging the support request : ${err?.response?.data?.message}`)
    })
  }

  const handleAnswerText = async (ev) => {
    ev.preventDefault()
    setAnswerRef(ev.target.value)
  }

  const handleAnswer = async (ev) => {
    ev.preventDefault()
    if(answerRef.current !== ``) {
      setSubmitLoadingRef(true)
      await Axios.post(config.api.url+`supports/answer?locale=fr`,{message:answerRef.current,request:supReqRef.current.id}).then(resp => {
        setAnswerRef(``)
        setSubmitLoadingRef(false)
        fri9g_notify.success(`Request answered successfully`)
        loadDatas()
      }).catch(err => {
        fri9g_notify.error(`Error while answering the support request : ${err?.response?.data?.message}`)
      })
    } else {
      fri9g_notify.error(`You need to enter an answer before submitting`)
    }
    
  }

  const handleStatus = async (ev,status) => {
    setSubmitLoadingRef(true)
    ev.preventDefault()
    await Axios.post(config.api.url+`supports/status?locale=fr`,{status,request:supReqRef.current.id}).then(resp => {
      fri9g_notify.success(`Request status updated`)
      setPopUpRef(null)
      setSubmitLoadingRef(false)
      loadDatas()
    }).catch(err => {
      fri9g_notify.error(`Error while updating the status : ${err?.response?.data?.message}`)
    })
  }

  useEffect(()=>{
    let chat = document.querySelector('.chat')
    if(chat) {
      chat.scrollTop = chat.scrollHeight
    }
  },[scrollRef.current])

  return (<div className="page supports">
    <div className="page-action">
      {id?
        <button className={'button cancel-button '+(!__access['support']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();navigate(`/support`)}} disabled={!__access['support']}><FontAwesomeIcon icon={faRightFromBracket} /> </button>
      :
        <div className="search">
          <input type="text" value={searchRef.current} onChange={(e) => {
            e.preventDefault()
            setSearchRef(e.target.value)
            if(e.target.value ==="") loadDatas()
          }}/>
          <div className="selector">
            {__selectorData.map((e,k)=>{
              if(e===selectorRef.current) return <h5 key={`${e}-${k}-selected`} onClick={(ev) => {ev.preventDefault();ev.target.parentNode.classList.toggle('active')}}>{e}</h5>
              else return null
            })}
            {__selectorData.map((e,k)=>{
              if(e!==selectorRef.current) return <h5 key={`${e}-${k}-not-selected`} onClick={(ev) => {ev.preventDefault();setSelectorRef(e);ev.target.parentNode.classList.toggle('active')}}>{e}</h5>
              else return null
            })}
          </div>
          <button className="button" onClick={ev => getSearch(ev)}><FontAwesomeIcon icon={faMagnifyingGlass} /></button>
        </div>}
    </div>
    {loadingRef.current ? <Loading/>: <>
      {id ? <> {supReqRef.current.message && <>
        <div className="support-grid">
          <div className="chat">
            <div className="chat-container">
              {supReqRef.current.message.map((e,k) => <div className={`message ${e.user.id === supReqRef.current.user.id ? 'owner-message' : 'team-message'}`} key={e.date+'-'+k}>
                <h5>{`${e.user.id === supReqRef.current.user.id ? 'Proprietaire' : 'FriShopping Tean'} - ${e.user.username}`}</h5>
                <h6>{new Date(e.date).toUTCString()}</h6>
                <h4>{e.message}</h4>
              </div>)}
            </div>
          </div>
          <div className="info">
            <h4>Type :</h4><h5>{supReqRef.current.type}</h5>
            <h4>Sujet :</h4><h5>{supReqRef.current.subject}</h5>
            <h4>Langue :</h4><h5>{supReqRef.current.locale.iso+'-'+supReqRef.current.locale.name}</h5>
            <h4>Username :</h4><h5>{supReqRef.current.user.username}</h5>
            <h4>Email :</h4><h5>{supReqRef.current.user.email}</h5>
            <h4>Status :</h4><h5>{supReqRef.current.status}</h5>
            <div className="status-action s2x">
              <button className="button edit-button" onClick={ev => {ev.preventDefault();setPopUpRef(`status`)}}>Modify status</button>
            </div>
          </div>
          <form className="textbox">
            {submitLoadingRef.current ? <div className="s2x"> <Loading/></div> : <>
              <textarea value={answerRef.current} onChange={ev => handleAnswerText(ev)}>{}</textarea>
              <button onClick={ev => handleAnswer(ev)}>
                <FontAwesomeIcon icon={faMessage} />
              </button>
            </>}
            
          </form>
        </div></>}
      </>
      :
      <div className="list-clipboard">
        <div className="list-title">
          <div>ID</div>
          <div>Date</div>
          <div>Sujet</div>
          <div>Type</div>
          <div>User</div>
          <div>Statut</div>
          <div></div>
        </div>
        {supReqsRef.current && supReqsRef.current.map((e,k) => <div className="list-entity" key={'list-'+k}>
          <div>{e.id}</div>
          <div>{new Date(e.updatedAt).toUTCString()}</div>
          <div>{`${e.subject}`}</div>
          <div>{`${e.type}`}</div>
          <div>{`${e.user.id}`}</div>
          <div>{`${e.status}`}</div>
          <div>
            <button className="button edit-button" onClick={(ev)=>{ev.preventDefault();navigate(`/support/${e.id}`)}}><FontAwesomeIcon icon={faEye} /></button>
          </div>
        </div>)}
      </div>}
    </>}
    {popUpRef.current && <PopUpWindow className={`form support-${popUpRef.current}`} close={()=>{setPopUpRef(false)}}>
        {popUpRef.current === 'status' && <>
          <h2>Change the status of the request</h2>
          <div className="buttons">
            {submitLoadingRef.current ? <Loading/> : <>
              <button className="edit-button" onClick={ev => handleStatus(ev,`ANSWERED`)}>ANSWERED</button>
              <button className="cancel-button" onClick={ev => handleStatus(ev,`PENDING`)}>PENDING</button>
              <button className="delete-button" onClick={ev => handleStatus(ev,`CLOSED`)}>CLOSED</button>
            </>}
          </div>
        </>}
      </PopUpWindow>}
  </div>)
}
export default Support