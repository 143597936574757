import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import authApi from "../services/authAPI"
import { fri9g_notify } from "fri9g-notification-react"
import config from "../config/api.config"
import Axios from "axios"
import Loading from "../components/Loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage, faPenToSquare, faSquarePlus } from "@fortawesome/free-regular-svg-icons"
import { faCheck, faEye, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons"
import PopUpWindow from "../components/PopUpWindow"

const Category = (props) => {
  const navigate = useNavigate()
  const setRoute = props.setRoute
  const __access = authApi.get('access')

  useEffect(()=>{
    if(!authApi.isAuthenticated()) navigate("/login")
    setRoute("Category")
    loadDatas()
  },[setRoute])

  const [loading,setLoading] = useState(true)
  const loadingRef = useRef(loading)
  const setLoadingRef = (data) => {
    loadingRef.current = data
    setLoading(data)
  }

  const [categories,setCategories] = useState([])
  const categoriesRef = useRef(categories)
  const setCategoriesRef = (data) => {
    categoriesRef.current = data
    setCategories(data)
  }

  const [category,setCategory] = useState([])
  const categoryRef = useRef(category)
  const setCategoryRef = (data) => {
    categoryRef.current = data
    setCategory(data)
  }

  const [locales,setLocales] = useState([])
  const localesRef = useRef(locales)
  const setLocalesRef = (data) => {
    localesRef.current = data
    setLocales(data)
  }

  const loadDatas = async () => {
    setLoadingRef(true)
    await Axios.get(config.api.url+'language?locale=fr&active=true').then(async res => {
      setLocalesRef(res.data)
      await Axios.get(config.api.url+'category/all').then(resp => { 
        setCategoriesRef(resp.data)
        setLoadingRef(false)
      })
    })
  }

  const toggleCategory = async (k) => {
    let cc = [...categoriesRef.current]
    cc[k].loading = true
    console.log(cc[k])
    setCategoriesRef(cc)
    await Axios.post(`${config.api.url}category/toggle`,{category:cc[k].id}).then(resp => {
      let cc = [...categoriesRef.current]
      cc[k].active = !cc[k].active
      cc[k].loading = false
      setCategoriesRef(cc)
    }).catch(err => {
      let cc = [...categoriesRef.current]
      cc[k].loading = false
      setCategoriesRef(cc)
      fri9g_notify.error('Error while toggling the category')
    })
  }

  const deleteCategory = async (k) => {
    let cc = [...categoriesRef.current]
    var index = null
    for (let i = 0; i < cc.length; i++) {
      const e = cc[i]
      if(e.id === k) index = i
    }
    cc[index].loading = true
    setCategoriesRef(cc)
    await Axios.post(`${config.api.url}category/delete`,{category:cc[index].id}).then(resp => {
      setCategoryRef({loading:false})
      loadDatas()
      fri9g_notify.success('Category deleted')
    }).catch(err => {
      let cc = [...categoriesRef.current]
      cc[index].loading = false
      setCategoriesRef(cc)
      fri9g_notify.error('Error while deleting the category')
    })
  }

  const loadPanelDatas = async () => {

  }

  const submitData = async () => {
    var current = categoryRef.current.category
    switch(categoryRef.current.action) {
      case 'new': 
        if(current.new_cover) {
          var f = new FormData()
          f.append('name',JSON.stringify(current.name))
          f.append('file',current.new_cover)
        } else {
          var f = {name:current.name}
        }
        await Axios.post(config.api.url+'category/new',f,{
          headers:{
            "Content-Type": current.new_cover ? "multipart/form-data" : "application/json"
          }
        }).then(resp => {
          setCategoryRef({loading:false})
          loadDatas()
          fri9g_notify.success('Category created')
        }).catch(err=>{
          fri9g_notify.error('Creation failed : '+err.message)
        })
        break
      case 'edit':
        if(current.new_cover) {
          var f = new FormData()
          f.append('name',JSON.stringify(current.name))
          f.append('file',current.new_cover)
          f.append('category',current.id)
        } else {
          var f = {category:current.id,name:current.name}
        }
        await Axios.post(config.api.url+'category/edit',f,{
          headers:{
            "Content-Type": current.new_cover ? "multipart/form-data" : "application/json"
          }
        }).then(resp => {
          setCategoryRef({loading:false})
          loadDatas()
          fri9g_notify.success('Category edited')
        }).catch(err=>{
          fri9g_notify.error('Edit failed : '+err.message)
        })
        break
      default:
    }
  }

  return (<div className="page categories">
    {loadingRef.current ? <Loading/>: <>
    <div className="page-action">
        <button className="button add-button" onClick={(e)=>{e.preventDefault();;setCategoryRef({
            action:'new',
            category:{name:{}},
            loading:false
          })}}>
          <FontAwesomeIcon icon={faSquarePlus} />
        </button>
      </div>
      <div className="list-clipboard">
        <div className="list-title">
          <div>Cover</div>
          <div>Name</div>
          <div>Active</div>
          <div></div>
        </div>
      
      {categoriesRef.current.length > 0 && categoriesRef.current.map((e,k) => <div className="list-entity" key={'list-'+k}>
          {e.loading?<Loading/>:<>
            <div>{e.cover!==null?<img className="picture" src={config.api.url+`images/s/${e.cover}`} alt={`cover of ${e.name[0]}`} />:<FontAwesomeIcon className="picture_default" icon={faImage} />}</div>
            <div>{`${Object.keys(e.name)[0]} - ${e.name[Object.keys(e.name)[0]]}`}</div>
            <div>{e.active?<FontAwesomeIcon className="font-icon green-font" icon={faCheck} onClick={()=>{if(__access['category_edit'])toggleCategory(k)}} />:<FontAwesomeIcon className="font-icon red-font" icon={faXmark} onClick={()=>{if(__access['category_edit'])toggleCategory(k)}} />}</div>
            <div>
              <button className={"button edit-button "+(!__access['category_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setCategoryRef({action:'edit',category:e,loading:false,tab:'info'});loadPanelDatas()}} disabled={!__access['category_edit']}><FontAwesomeIcon icon={faPenToSquare} /></button>
              <button className={'button delete-button '+(!__access['category_edit']?'disabled-button':'')} onClick={(ev)=>{ev.preventDefault();setCategoryRef({action:'delete',category:e,loading:false});}} disabled={!__access['category_edit']}><FontAwesomeIcon icon={faTrashCan} /></button>
            </div>
          </>}
        </div> )}
      </div>
      {/* window form part */}
      {categoryRef.current.category && 
        <PopUpWindow className={`form category-${categoryRef.current.action}`} close={()=>{setCategoryRef({loading:false})}}>
          {/* New Panel */}
          {categoryRef.current.action === 'new' && <>
            <div className="popup-window-panel">
              {categoryRef.current.loading?<Loading/>:<>
              <div className="edit-grid panel-grid">
                  <div className="block">
                    <h4>Name</h4>
                    <div className="list">
                      {console.log(categoryRef.current)}
                      {/* {categoryRef.current.category && categoryRef.current.category.name && Object.keys(categoryRef.current.category.name).length>0 && Object.keys(categoryRef.current.category.name).map((k) => { return categoryRef.current.category.name[k] ? <h5 key={k}> */}
                      {categoryRef.current.category && categoryRef.current.category.name && localesRef.current.length>0 && localesRef.current.map((k) => { return <h5 key={k.iso}>
                        <span>{k.iso}</span>
                        <input type="text" defaultValue={categoryRef.current.category.name[k.iso]?categoryRef.current.category.name[k.iso]:""} onChange={(e)=>{e.preventDefault();let r = {...categoryRef.current};r.category.name[k.iso]=e.target.value;setCategoryRef(r)}}/>
                      </h5>} )}
                    </div>
                  </div>
                  <div className="block">
                    <h4>Cover</h4>
                    <div className="container">
                      <div className="half">
                        {categoryRef.current.category.new_cover|| categoryRef.current.category.cover ?<img className="picture" src={categoryRef.current.category.new_cover? URL.createObjectURL(categoryRef.current.category.new_cover) :config.api.url+`images/src/${categoryRef.current.category.cover}`} alt={`cover of ${categoryRef.current.category.name[0]}`} /> : <FontAwesomeIcon className="picture_default" icon={faImage} />}
                      </div>
                      <div className="half">
                        <input type="file" onChange={(e)=>{e.preventDefault();let r = {...categoryRef.current};r.category.new_cover=e.target.files[0];setCategoryRef(r)}}/>
                      </div>
                    </div>
                    {/* <h3>Email</h3>
                    <h4><input type="text" value={categoryRef.current.user.email} onChange={(e)=>{e.preventDefault();let u = {...categoryRef.current};u.user.email=e.target.value;setCategoryRef(u)}}/></h4> */}
                  </div>
                </div>
              <div className="action-grid">
                <button className="edit-button" onClick={(e)=>{e.preventDefault();submitData()}}>Submit</button>
                <button className="delete-button" onClick={()=>{setCategoryRef({loading:false})}}>Cancel</button>
              </div>
              </>}
            </div>
          </>}

          {/* Edit Panel */}
          {categoryRef.current.action === 'edit' && <>
            <div className="popup-window-panel">
              {categoryRef.current.loading?<Loading/>:<>
              <div className="edit-grid panel-grid">
                  <div className="block">
                    <h4>Name</h4>
                    <div className="list">
                      {console.log(categoryRef.current)}
                      {/* {categoryRef.current.category && categoryRef.current.category.name && Object.keys(categoryRef.current.category.name).length>0 && Object.keys(categoryRef.current.category.name).map((k) => { return categoryRef.current.category.name[k] ? <h5 key={k}> */}
                      {categoryRef.current.category && categoryRef.current.category.name && localesRef.current.length>0 && localesRef.current.map((k) => { return <h5 key={k.iso}>
                        <span>{k.iso}</span>
                        <input type="text" defaultValue={categoryRef.current.category.name[k.iso]?categoryRef.current.category.name[k.iso]:""} onChange={(e)=>{e.preventDefault();let r = {...categoryRef.current};r.category.name[k.iso]=e.target.value;setCategoryRef(r)}}/>
                      </h5>} )}
                    </div>
                  </div>
                  <div className="block">
                    <h4>Cover</h4>
                    <div className="container">
                      <div className="half">
                        {categoryRef.current.category.new_cover|| categoryRef.current.category.cover ?<img className="picture" src={categoryRef.current.category.new_cover? URL.createObjectURL(categoryRef.current.category.new_cover) :config.api.url+`images/src/${categoryRef.current.category.cover}`} alt={`cover of ${categoryRef.current.category.name[0]}`} /> : <FontAwesomeIcon className="picture_default" icon={faImage} />}
                      </div>
                      <div className="half">
                        <input type="file" onChange={(e)=>{e.preventDefault();let r = {...categoryRef.current};r.category.new_cover=e.target.files[0];setCategoryRef(r)}}/>
                      </div>
                    </div>
                    {/* <h3>Email</h3>
                    <h4><input type="text" value={categoryRef.current.user.email} onChange={(e)=>{e.preventDefault();let u = {...categoryRef.current};u.user.email=e.target.value;setCategoryRef(u)}}/></h4> */}
                  </div>
                </div>
              <div className="action-grid">
                <button className="edit-button" onClick={(e)=>{e.preventDefault();submitData()}}>Submit</button>
                <button className="delete-button" onClick={()=>{setCategoryRef({loading:false})}}>Cancel</button>
              </div>
              </>}
            </div>
          </>}

          {/* Delete Panel */}
          {categoryRef.current.action === 'delete' && <>
              {console.log(categoryRef.current)}
            <div className="popup-window-panel">
              Confirm delete of category {`${Object.keys(categoryRef.current.category.name)[0]} - ${categoryRef.current.category.name[Object.keys(categoryRef.current.category.name)[0]]}`}
              <div className="action-grid">
                <button className="edit-button" onClick={(e)=>{e.preventDefault();deleteCategory(categoryRef.current.category.id)}}>Submit</button>
                <button className="delete-button" onClick={()=>{setCategoryRef({loading:false})}}>Cancel</button>
              </div>
            </div>
          </>}
        </PopUpWindow>}
      </>}
  </div>)
}
export default Category